import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "imported-scans"
}
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[11] || (_cache[11] = _createTextVNode("Imported Scans")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (!_ctx.projectId || _ctx.projectId === 0)
          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Select a project to continue...")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.projectId || _ctx.projectId > 0)
          ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  headers: $data.headers,
                  "show-group-by": "",
                  items: _ctx.importedScans || [],
                  "item-key": "id",
                  search: $data.search,
                  loading: $options.loading,
                  width: "800px",
                  "loading-text": "Loading... Please wait",
                  "footer-props": {"items-per-page-options":[25,50,100,-1]},
                  "items-per-page": 25
                }, {
                  "item.artifacts": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString($options.getStatus(item)), 1)
                  ]),
                  "expanded-row": _withCtx(({ headers, item }) => [
                    _createElementVNode("td", {
                      colspan: headers?.length
                    }, _toDisplayString(item.comment), 9, _hoisted_2)
                  ]),
                  top: _withCtx(() => [
                    _createVNode(_component_v_toolbar, {
                      flat: "",
                      color: "transparent"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.uploadDialog,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.uploadDialog) = $event)),
                          "max-width": "550px"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              class: "mr-2",
                              color: "primary lighten-2"
                            }, props), {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("mdi-plus-circle")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            ($data.uploadDialog)
                              ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_3, _toDisplayString($options.formTitle), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_form, {
                                          modelValue: $data.isFormValid,
                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.isFormValid) = $event)),
                                          ref: "createForm",
                                          disabled: $data.edittingLocked
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_container, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $data.editedItem.friendlyName,
                                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.editedItem.friendlyName) = $event)),
                                                          density: "compact",
                                                          label: "Name",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_textarea, {
                                                          modelValue: $data.editedItem.comment,
                                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.editedItem.comment) = $event)),
                                                          density: "compact",
                                                          clearable: "",
                                                          rows: "2",
                                                          label: "Comment"
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      cols: "9",
                                                      style: {"height":"fit-content"}
                                                    }, {
                                                      default: _withCtx(() => [
                                                        (!$options.isEditting)
                                                          ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                                                              key: 0,
                                                              style: {"white-space":"wrap"}
                                                            }, {
                                                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                                                _createTextVNode("Uploaded LAS files "),
                                                                _createElementVNode("b", null, "must", -1),
                                                                _createTextVNode(" be in WGS84/UTM. Specify the UTM as XX"),
                                                                _createElementVNode("b", null, "N", -1),
                                                                _createTextVNode(" (North) or XX"),
                                                                _createElementVNode("b", null, "S", -1),
                                                                _createTextVNode(" (South).")
                                                              ])),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true),
                                                        _createVNode(_component_v_card_subtitle, { style: {"white-space":"wrap"} }, {
                                                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                            _createTextVNode("If unchecked, scan will be represented in 2D viewer as bounding box. ")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-8",
                                                      cols: "2"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $data.editedItem.utm,
                                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.editedItem.utm) = $event)),
                                                          density: "compact",
                                                          label: "UTM",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { style: {"display":"flex","flex-direction":"row","justify-content":"flex-end"} }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_checkbox, {
                                                      class: "pt-8",
                                                      modelValue: $data.editedItem.trajectory,
                                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.editedItem.trajectory) = $event)),
                                                      label: "Determine Trajectory",
                                                      density: "compact"
                                                    }, null, 8, ["modelValue"])
                                                  ]),
                                                  _: 1
                                                }),
                                                (!$options.isEditting)
                                                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_card, {
                                                          width: "100%",
                                                          flat: "",
                                                          onDrop: _withModifiers(_ctx.addOneDropFile, ["prevent"]),
                                                          onDragover: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_file_input, {
                                                              accept: ".las",
                                                              modelValue: _ctx.file,
                                                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.file) = $event)),
                                                              "truncate-length": "15",
                                                              density: "compact",
                                                              "show-size": "",
                                                              rules: _ctx.singleLasFileInputRules
                                                            }, null, 8, ["modelValue", "rules"]),
                                                            (_ctx.showUpload)
                                                              ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                                                                  key: 0,
                                                                  max: "100",
                                                                  modelValue: _ctx.fileUploadPercentage,
                                                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fileUploadPercentage) = $event))
                                                                }, null, 8, ["modelValue"]))
                                                              : _createCommentVNode("", true)
                                                          ]),
                                                          _: 1
                                                        }, 8, ["onDrop"])
                                                      ]),
                                                      _: 1
                                                    }))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue", "disabled"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, { "hide-details": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.closeUpload,
                                          disabled: _ctx.uploadingFile
                                        }, {
                                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                                            _createTextVNode("Cancel")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"]),
                                        (!$options.isEditting)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              color: "blue darken-1",
                                              text: "",
                                              onClick: $options.save,
                                              disabled: !$data.isFormValid || _ctx.uploadingFile
                                            }, {
                                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                _createTextVNode("Upload")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick", "disabled"]))
                                          : _createCommentVNode("", true),
                                        ($options.isEditting)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 1,
                                              color: "blue darken-1",
                                              text: "",
                                              onClick: $options.save,
                                              disabled: !$data.isFormValid
                                            }, {
                                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                _createTextVNode("Save")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick", "disabled"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.deleteDialog,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($data.deleteDialog) = $event)),
                          "max-width": "500px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "headline" }, {
                                  default: _withCtx(() => [
                                    _cache[19] || (_cache[19] = _createTextVNode("Confirm Deletion of Imported Scan")),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Are you sure you want to delete the imported scan '" + _toDisplayString($data.editedItem.name) + "'?", 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_actions, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_spacer),
                                    _createVNode(_component_v_btn, {
                                      color: "blue darken-1",
                                      text: "",
                                      onClick: $options.closeDelete
                                    }, {
                                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                                        _createTextVNode("Cancel")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"]),
                                    _createVNode(_component_v_btn, {
                                      color: "blue darken-1",
                                      text: "",
                                      onClick: $options.deleteItemConfirm
                                    }, {
                                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                                        _createTextVNode("OK")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      small: "",
                      onClick: ($event: any) => ($options.editItem(item))
                    }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode("mdi-pencil")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_v_icon, {
                      small: "",
                      onClick: ($event: any) => ($options.deleteItem(item))
                    }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("mdi-delete")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["headers", "items", "search", "loading"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}