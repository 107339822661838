import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "layers"
}
const _hoisted_2 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_JsonEditorVue = _resolveComponent("JsonEditorVue")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[12] || (_cache[12] = _createTextVNode("Layers")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (!_ctx.projectId || _ctx.projectId === 0)
          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Select a project to continue...")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.projectId || _ctx.projectId > 0)
          ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  headers: $data.headers,
                  "show-group-by": "",
                  items: $data.projectLayers || [],
                  "item-key": "id",
                  search: $data.search,
                  loading: $options.loading,
                  width: "800px",
                  "loading-text": "Loading... Please wait",
                  "footer-props": {"items-per-page-options":[25,50,100,-1]},
                  "items-per-page": 25
                }, {
                  "item.ignore": _withCtx(({ item }) => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: item.ignore,
                      "onUpdate:modelValue": ($event: any) => ((item.ignore) = $event),
                      disabled: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  "item.showByDefault": _withCtx(({ item }) => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: item.showByDefault,
                      "onUpdate:modelValue": ($event: any) => ((item.showByDefault) = $event),
                      disabled: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  "item.lastModified": _withCtx(({ item }) => [
                    _createElementVNode("template", null, [
                      _createTextVNode(_toDisplayString(new Date(item.lastModified).toLocaleString()), 1)
                    ])
                  ]),
                  top: _withCtx(() => [
                    _createVNode(_component_v_toolbar, {
                      flat: "",
                      color: "transparent"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.dialog,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($data.dialog) = $event)),
                          "max-width": "850px"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              class: "mr-2",
                              color: "primary lighten-2"
                            }, props), {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createTextVNode("mdi-plus-circle")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            ($data.dialog)
                              ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_2, _toDisplayString($options.formTitle), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_form, {
                                          modelValue: $data.isFormValid,
                                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.isFormValid) = $event)),
                                          ref: "createForm"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_container, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "12"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $data.editedItem.name,
                                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.editedItem.name) = $event)),
                                                          density: "compact",
                                                          label: "Name",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_checkbox, {
                                                          label: "Hide",
                                                          density: "compact",
                                                          modelValue: $data.editedItem.ignore,
                                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.editedItem.ignore) = $event))
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_checkbox, {
                                                          label: "Show By Default",
                                                          density: "compact",
                                                          modelValue: $data.editedItem.showByDefault,
                                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.editedItem.showByDefault) = $event))
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "12"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $data.editedItem.comments,
                                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.editedItem.comments) = $event)),
                                                          density: "compact",
                                                          label: "Comments"
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "ma-0 pa-0",
                                                      cols: "12"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_tabs, {
                                                          class: "ma-0 pa-0 pl-3",
                                                          modelValue: $data.selectedTab,
                                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.selectedTab) = $event))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_tab, null, {
                                                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                                _createTextVNode("Source")
                                                              ])),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_v_tab, null, {
                                                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                                _createTextVNode("Rendering Layer")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        ($data.selectedTab === 0)
                                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                              _createVNode(_component_v_row, null, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_col, { cols: "12" }, {
                                                                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                                      _createTextVNode("Source data in GeoJSON format. Mapview only supports Mercator projection. Ensure coordinates are in Latitude and Longitude.")
                                                                    ])),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_v_row, null, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_col, { cols: "12" }, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_JsonEditorVue, {
                                                                        modelValue: $data.editedItem.source,
                                                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.editedItem.source) = $event)),
                                                                        mode: "Mode.text",
                                                                        stringified: false
                                                                      }, null, 8, ["modelValue"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              })
                                                            ], 64))
                                                          : _createCommentVNode("", true),
                                                        ($data.selectedTab === 1)
                                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                              _createVNode(_component_v_row, null, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_col, { cols: "8" }, {
                                                                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                                      _createTextVNode("An array of MapboxGL rendering layers to be applied to the Source. Do not include id or source as these are dynamically applied.")
                                                                    ])),
                                                                    _: 1
                                                                  }),
                                                                  _createVNode(_component_v_col, { cols: "4" }, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_v_select, {
                                                                        dense: "",
                                                                        placeholder: "Add Example",
                                                                        modelValue: $data.exampleLayer,
                                                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.exampleLayer) = $event)),
                                                                        items: $data.exampleLayers,
                                                                        "item-title": 'name',
                                                                        "item-value": 'value'
                                                                      }, null, 8, ["modelValue", "items"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_v_row, null, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_col, { cols: "12" }, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_JsonEditorVue, {
                                                                        modelValue: $data.editedItem.layers,
                                                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.editedItem.layers) = $event)),
                                                                        mode: "Mode.text",
                                                                        stringified: false
                                                                      }, null, 8, ["modelValue"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              })
                                                            ], 64))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, { "hide-details": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.close
                                        }, {
                                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                                            _createTextVNode("Cancel")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick"]),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.save,
                                          disabled: !$data.isFormValid
                                        }, {
                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                            _createTextVNode("Save")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.dialogDelete,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.dialogDelete) = $event)),
                          "max-width": "500px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "headline" }, {
                                  default: _withCtx(() => [
                                    _cache[21] || (_cache[21] = _createTextVNode("Confirm Project Layer Deletion")),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Are you sure you want to delete project layer '" + _toDisplayString($data.editedItem.name) + "'?", 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_actions, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_spacer),
                                    _createVNode(_component_v_btn, {
                                      color: "blue darken-1",
                                      text: "",
                                      onClick: $options.closeDelete
                                    }, {
                                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                                        _createTextVNode("Cancel")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"]),
                                    _createVNode(_component_v_btn, {
                                      color: "blue darken-1",
                                      text: "",
                                      onClick: $options.deleteItemConfirm
                                    }, {
                                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                                        _createTextVNode("OK")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      small: "",
                      onClick: ($event: any) => ($options.editItem(item))
                    }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("mdi-pencil")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_v_icon, {
                      small: "",
                      onClick: ($event: any) => ($options.deleteItem(item))
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("mdi-delete")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["headers", "items", "search", "loading"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}