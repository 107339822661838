import { GET_PACKAGE_UPLOAD_URL } from "../graphql/package-upload-queries";
import { PackageUploadActivityLogger } from "../services/activity-logger";
import { apolloClient } from "../services/vue-apollo";
import { calculateRate } from "../services/spacetime-util";
import { reactive } from "vue";
import { v4 as uuidv4 } from "uuid";

export class FileUploadProgressMonitor {
  totalBytes: number;
  uploadedBytes: number;
  progress: number;

  rateCalculationStartTime: Date;
  rateCalculationLastSize: number;
  rateCalculation: number; // bytes / ms
  rateCalculationInterval: ReturnType<typeof setInterval>;

  constructor(fileSize: number) {
    this.totalBytes = fileSize;
    this.uploadedBytes = 0;
    this.progress = 0;

    this.rateCalculationStartTime = new Date();
    this.rateCalculationLastSize = 0;
    this.rateCalculation = 0;
    this.rateCalculationInterval = null;
  }

  startProgressMonitoring() {
    this.rateCalculationInterval = setInterval(() => {
      const currentTime = new Date();
      this.rateCalculation = calculateRate(
        this.rateCalculationLastSize,
        this.uploadedBytes,
        this.rateCalculationStartTime,
        currentTime
      );
      this.rateCalculationStartTime = currentTime;
      this.rateCalculationLastSize = this.uploadedBytes;
    }, 5000);
  }
  stopProgressMonitoring() {
    clearInterval(this.rateCalculationInterval);
  }
  setProgress(newProgress: number) {
    if (newProgress < this.progress + 0.1) {
      return;
    }
    this.progress = newProgress;
    this.uploadedBytes = (this.totalBytes * this.progress) / 100;
  }
  setComplete() {
    this.stopProgressMonitoring();
    this.progress = 100;
    this.uploadedBytes = this.totalBytes;
  }
}

export class FileUploadState {
  hasStarted: boolean;
  isStarting: boolean;
  isUploading: boolean;
  isComplete: boolean;
  hasError: boolean;
  lastError;

  constructor() {
    this.hasStarted = false;
    this.isStarting = false;
    this.isUploading = false;
    this.isComplete = false;
    this.hasError = false;
    this.lastError = null;
  }
  setStarting() {
    this.isStarting = true;
  }
  setInProgress() {
    this.isStarting = false;
    this.hasStarted = true;
    this.isUploading = true;
  }
  setComplete() {
    this.isStarting = false;
    this.hasStarted = true;
    this.isUploading = false;
    this.isComplete = true;
  }
  setError(error) {
    this.isStarting = false;
    this.isUploading = false;
    this.isComplete = false;
    this.hasError = true;

    this.lastError = error;
  }

  static compareValue(aValue: boolean, bValue: boolean): number {
    if (aValue) {
      if (bValue) {
        return 0;
      }
      return -1;
    }
    if (bValue) {
      return 1;
    }
    return 0;
  }
  static compareStateForDisplay(
    a: FileUploadState,
    b: FileUploadState
  ): number {
    // isUploading, isStarting, hasError, !hasStarted, isComplete
    let currentCompare = 0;
    currentCompare = FileUploadState.compareValue(a.isUploading, b.isUploading);
    if (currentCompare !== 0) {
      return currentCompare;
    }

    currentCompare = FileUploadState.compareValue(a.isStarting, b.isStarting);
    if (currentCompare !== 0) {
      return currentCompare;
    }

    currentCompare = FileUploadState.compareValue(a.hasError, b.hasError);
    if (currentCompare !== 0) {
      return currentCompare;
    }

    currentCompare = FileUploadState.compareValue(!a.hasStarted, !b.hasStarted);
    if (currentCompare !== 0) {
      return currentCompare;
    }

    currentCompare = FileUploadState.compareValue(a.isComplete, b.isComplete);
    if (currentCompare !== 0) {
      return currentCompare;
    }

    return 0;
  }
}

export class FileUploadWorkerInfo {
  public id: string;
  createdAt: Date;
  startTime: Date;
  name: string;

  progressMonitor: FileUploadProgressMonitor;
  state: FileUploadState;

  url: string;
  file: any;
  onStarted: (message: string) => void = () => {
    /* no-op */
  };
  onProgress: (progress: number, uploadedBytes: number) => void = () => {
    /* no-op */
  };
  onComplete: () => void;
  onError: (error) => void;

  constructor(file: any, url?: string) {
    this.id = uuidv4();
    this.createdAt = new Date();
    this.file = file;
    this.url = url;
    this.name = this.file.name;
    this.progressMonitor = reactive(
      new FileUploadProgressMonitor(this.file.size)
    );
    this.state = new FileUploadState();
  }

  completeUpload() {
    this.progressMonitor.setComplete();
    this.state.setComplete();

    const activityLogger = new PackageUploadActivityLogger();
    activityLogger.updatePackageUploadActivityLog(
      this.name,
      "Completed",
      this.name,
      this.progressMonitor.totalBytes
    );
    if (this.onComplete) {
      this.onComplete();
    }
  }

  startSinglePartUpload(url: string) {
    this.url = url;
    const worker = new Worker("worker-file-upload.js");
    worker.onmessage = function (e) {
      switch (e.data.type) {
        case "progress":
          console.log("Raw progress from worker:", e.data.message);
          this.progressMonitor.setProgress(e.data.message);
          if (this.onProgress) {
            this.onProgress(
              this.progressMonitor.progress,
              this.progressMonitor.uploadedBytes
            );
          }
          break;
        case "complete":
          this.completeUpload();
          break;
        case "error":
          this.progressMonitor.stopProgressMonitoring();
          this.state.setError(e.data);
          if (this.onError) {
            this.onError(e.data);
          }
          break;
      }
    }.bind(this);
    worker.onerror = function (e) {
      if (this.onError) {
        this.onError(e);
      }
    }.bind(this);

    worker.postMessage([this.file, this.url]);
    this.state.setInProgress();
    this.progressMonitor.startProgressMonitoring();
    this.startTime = new Date();
    this.onStarted(`Started uploading ${this.file.name}...`);
  }

  startUpload() {
    apolloClient
      .query({
        query: GET_PACKAGE_UPLOAD_URL,
        variables: { filename: this.name },
      })
      .then((res) => {
        this.startSinglePartUpload(res.data.getPackageUploadSignedURL);
      })
      .catch((ex) => {
        console.error(ex);
        if (this.onError) {
          this.onError(ex);
        }
      });
  }
}
