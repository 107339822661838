import {
  calculateRate,
  formatBytes,
  getEstimatedRemainingByRate,
} from "../services/spacetime-util";

import { FileMultipartUploadWorkerInfo } from "../models/file-multipart-upload-worker-info-model";
import { FileUploadWorkerInfo } from "../models/file-upload-worker-info-model";
import { PackageUploadActivityLogger } from "../services/activity-logger";
import { reactive } from "vue";

function dummyFunction(e) {
  e.returnValue = `This won't get displayed`;
}

const fileUploadStoreModule = {
  namespaced: true,
  state: {
    workerInfoList: [],
    uploading: false,
    rateCalculationStartTime: null,
    rateCalculationLastSize: 0,
    rateCalculation: 0, // bytes / ms
    rateCalculationInterval: null,
    config: {
      numberOfSimultaneousFileUploads: 3,
      useMultipart: true,
    },
    completedUploadedBytes: 0,
    completedTotalBytes: 0,
  },
  mutations: {
    updateWorkerProgress(state, { workerId, progress, uploadedBytes }) {
      const worker = state.workerInfoList.find((w) => w.id === workerId);
      if (worker) {
        worker.progressMonitor.progress = progress;
        worker.progressMonitor.uploadedBytes = uploadedBytes;
      }
    },
    addUploadWorker(state, newWorker: FileUploadWorkerInfo) {
      state.workerInfoList.push(newWorker);
    },
    removeFinishedUploaders(state) {
      state.workerInfoList = state.workerInfoList.filter((worker) => {
        if (worker.state.isComplete) {
          state.completedUploadedBytes += worker.progressMonitor.uploadedBytes;
          state.completedTotalBytes += worker.progressMonitor.totalBytes;
          return false;
        }
        return true;
      });
    },
    startUploading(state) {
      if (!state.uploading) {
        // routing to check progress
        state.rateCalculationInterval = setInterval(() => {
          const uploadedSize = state.workerInfoList.reduce(
            (runningSum: number, worker: FileUploadWorkerInfo) =>
              runningSum + worker.progressMonitor.uploadedBytes,
            0
          );
          const currentTime = new Date();
          state.rateCalculation = calculateRate(
            state.rateCalculationLastSize,
            uploadedSize,
            state.rateCalculationStartTime,
            currentTime
          );
          state.rateCalculationStartTime = currentTime;
          state.rateCalculationLastSize = uploadedSize;
        }, 5000);
        state.rateCalculationStartTime = new Date();
        state.rateCalculationLastSize = 0;
        state.uploading = true;
        //console.log('adding browser close block listener')
        window.addEventListener("beforeunload", dummyFunction);
      }
    },
    completeUploading(state) {
      if (state.workerInfoList.length === 0) {
        //console.log('removing browser close block listener')
        window.removeEventListener("beforeunload", dummyFunction);
        state.uploading = false;
        clearInterval(state.rateCalculationInterval);
      }
    },
    setConfig(state, newValue) {
      state.config = {
        ...state.config,
        ...newValue,
      };
      console.log(
        `file upload config has been updated to: ${JSON.stringify(
          state.config
        )}`
      );
    },
  },
  actions: {
    uploadFile({ state, commit, dispatch }, params) {
      commit("startUploading");
      const activityLogger = new PackageUploadActivityLogger();
      const workerInstance = state.config.useMultipart
        ? new FileMultipartUploadWorkerInfo(params.file)
        : new FileUploadWorkerInfo(params.file);
      const newWorker = reactive(workerInstance);

      newWorker.onStarted = (message) => {
        params.snackbar.showMessage(message, "green", 2000);
        activityLogger.updatePackageUploadActivityLog(
          params.file.name,
          "Started",
          params.file.name,
          params.file.size
        );
      };

      newWorker.onProgress = (progress: number, uploadedBytes: number) => {
        commit("updateWorkerProgress", {
          workerId: newWorker.id,
          progress,
          uploadedBytes,
        });
      };

      newWorker.onComplete = () => {
        params.snackbar.showMessage(
          `Completed upload of '${newWorker.name}'`,
          "green",
          2000
        );
        dispatch("startNextUploadWorker");
      };
      newWorker.onError = (e) => {
        console.error("Upload error:", e);
        params.snackbar.showMessage(e.message, "error");
        activityLogger.error(params.file.name, e.message);
        dispatch("startNextUploadWorker");
      };

      commit("addUploadWorker", newWorker);
      dispatch("startNextUploadWorker");
    },
    startNextUploadWorker({ state }) {
      const currentlyUploadingCount = state.workerInfoList.filter(
        (w) => w.state.isStarting || w.state.isUploading
      ).length;
      const uploadCapacity =
        state.config.numberOfSimultaneousFileUploads - currentlyUploadingCount;
      if (uploadCapacity > 0) {
        const workersToUpload = state.workerInfoList
          .filter((w) => !w.state.hasStarted && !w.state.isStarting)
          .slice(0, uploadCapacity);
        workersToUpload.forEach(function (nextWorker) {
          nextWorker.state.setStarting();
          console.log(`Starting upload of '${nextWorker.name}'...`);
          nextWorker.startUpload();
        });
      }
    },
    removeFinishedUploaders({ commit, dispatch }) {
      commit("removeFinishedUploaders");
      dispatch("startNextUploadWorker");
      if (!this.hasQueuedOrActiveUploads) {
        commit("completeUploading");
      }
    },
  },
  getters: {
    config: (state) => state.config,
    workerInfoList: (state) => state.workerInfoList,
    overallProgress: (state, getters) => {
      const activeUploaded = getters.overallSizeUploaded;
      const activeTotal = getters.overallSizeTotal;
      const cumulativeUploaded = state.completedUploadedBytes + activeUploaded;
      const cumulativeTotal = state.completedTotalBytes + activeTotal;
      return cumulativeTotal > 0
        ? (cumulativeUploaded / cumulativeTotal) * 100
        : 100;
    },
    overallSizeTotal: (state) => {
      const totalSize = state.workerInfoList.reduce(
        (runningSum, worker) => runningSum + worker.progressMonitor.totalBytes,
        0
      );
      return totalSize;
    },
    overallSizeUploaded: (state) => {
      const uploadedSize = state.workerInfoList.reduce(
        (runningSum, worker) =>
          runningSum + worker.progressMonitor.uploadedBytes,
        0
      );
      return uploadedSize;
    },
    overallSizeProgress: (state, getters) => {
      return getters.overallSizeTotal > 0
        ? (getters.overallSizeUploaded / getters.overallSizeTotal) * 100
        : 0;
    },
    overallProgressDisplay: (state, getters) => {
      const uploadedSize = formatBytes(getters.overallSizeUploaded);
      console.log("uploadedSize", uploadedSize);
      const totalSize = formatBytes(getters.overallSizeTotal);
      console.log("totalSize", totalSize);
      const weightedProgress = getters.overallProgress.toFixed(2);
      console.log("weightedProgress", weightedProgress);
      const timeRemaining = getEstimatedRemainingByRate(
        getters.overallSizeTotal - getters.overallSizeUploaded,
        state.rateCalculation
      );

      console.log("timeRemaining", timeRemaining);

      console.log(
        "${weightedProgress}% (${uploadedSize} / ${totalSize} uploaded, estimated ${timeRemaining})",
        `${weightedProgress}% (${uploadedSize} / ${totalSize} uploaded, estimated ${timeRemaining})`
      );

      return `${weightedProgress}% (${uploadedSize} / ${totalSize} uploaded, estimated ${timeRemaining})`;
    },
    isUploading: (state) =>
      state.workerInfoList.some((w) => w.state.isUploading),
    queuedOrActiveUploads: (state) =>
      state.workerInfoList.filter(
        (w) => !w.state.hasStarted || w.state.isUploading
      ),
    queuedOrActiveUploadCount: (state, getters) =>
      getters.queuedOrActiveUploads.length,
    hasQueuedOrActiveUploads: (state, getters) =>
      getters.queuedOrActiveUploads.length > 0,
  },
};

export default fileUploadStoreModule;
