import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "imageView" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-end align-center pr-8",
  id: "imageNone",
  style: {"background-color":"rgba(0,0,0,.4)","height":"50px","z-index":"999","position":"absolute","bottom":"0px","left":"0px","right":"0px","color":"white"}
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex justify-end align-center",
  id: "imageHidden",
  style: {"background-color":"rgba(0,0,0,.4)","height":"50px","z-index":"999","position":"absolute","bottom":"0px","left":"0px","right":"0px","color":"white"}
}
const _hoisted_4 = {
  key: 1,
  id: "imageShow",
  style: {"background-color":"rgba(0,0,0,.3)","height":"280px","z-index":"999","position":"absolute","bottom":"0px","left":"0px","right":"0px"}
}
const _hoisted_5 = {
  class: "ma-0 pa-0 d-flex flex-column align-center justify-center",
  id: "controls",
  style: {"position":"absolute","z-index":"1000","top":"5px","right":"20px","height":"260px"}
}
const _hoisted_6 = {
  class: "d-flex justify-end align-center pr-8",
  id: "imageLabel",
  style: {"background-color":"rgba(0,0,0,0)","height":"50px","z-index":"999","position":"absolute","bottom":"0px","left":"0px","right":"0px","color":"white"}
}
const _hoisted_7 = {
  class: "d-flex justify-space-between align-center ml-auto mr-auto",
  id: "images",
  style: {"position":"relative","width":"100%","padding-right":"15vw"}
}
const _hoisted_8 = {
  key: 0,
  class: "ma-0 pa-0 justify-center align-center",
  id: "leftImage"
}
const _hoisted_9 = {
  key: 1,
  class: "ma-0 pa-0 justify-center align-center",
  id: "centerImage"
}
const _hoisted_10 = {
  key: 2,
  class: "ma-0 pa-0 justify-center align-center",
  id: "rightImage"
}
const _hoisted_11 = {
  key: 3,
  id: "rightImage",
  style: {"height":"280px","width":"33.33%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_img = _resolveComponent("v-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.imageViewVisible && _ctx.focusedScan)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($options.imageCount === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getScanName(_ctx.focusedScan)) + " has no image sets", 1))
            : _createCommentVNode("", true),
          ($options.imageCount > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.getScanName(_ctx.focusedScan)) + " has " + _toDisplayString(_ctx.focusedScan.images?.length) + " image sets", 1),
                _createVNode(_component_v_btn, {
                  icon: "",
                  color: "transparent",
                  onClick: _ctx.showImageView,
                  flat: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("mdi-arrow-expand-up")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.imageViewVisible && _ctx.focusedScan && _ctx.focusedImageSet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_btn, {
            icon: "",
            color: "transparent",
            onClick: _ctx.hideImageView,
            style: {"position":"absolute","z-index":"1050","top":"5px","right":"5px"},
            flat: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { color: "white" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("mdi-arrow-expand-down")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  icon: "",
                  color: "transparent"
                }, props, {
                  onClick: $options.nextImageSet,
                  flat: ""
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "white" }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("mdi-chevron-double-up")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040, ["onClick"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Next image set")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_chip, {
              class: "justify-center",
              color: "black",
              style: {"background":"white"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(parseInt(_ctx.focusedImageSet.id) + 1) + "/" + _toDisplayString(_ctx.focusedScan.images?.length), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  icon: "",
                  color: "transparent"
                }, props, {
                  onClick: $options.prevImageSet,
                  flat: ""
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "white" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("mdi-chevron-double-down")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040, ["onClick"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Previous image set")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getScanName(_ctx.focusedScan)), 1),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.focusedImageSet.leftImage !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  ($options.imageRotated(_ctx.focusedImageSet.leftImage))
                    ? (_openBlock(), _createBlock(_component_v_img, {
                        key: 0,
                        class: "ml-12",
                        contain: "",
                        src: _ctx.focusedImageSet.leftImage.s3Url,
                        "min-width": "200px",
                        "max-height": "280",
                        "max-width": _ctx.sideCameraWidth,
                        style: _normalizeStyle({ transform: 'rotate('+ _ctx.leftRotate +'turn)'})
                      }, null, 8, ["src", "max-width", "style"]))
                    : (_openBlock(), _createBlock(_component_v_img, {
                        key: 1,
                        class: "ml-12",
                        contain: "",
                        src: _ctx.focusedImageSet.leftImage.s3Url,
                        "min-width": "200px",
                        "max-height": _ctx.sideCameraHeight,
                        "max-width": _ctx.sideCameraWidth
                      }, null, 8, ["src", "max-height", "max-width"])),
                  _createVNode(_component_v_chip, {
                    class: "ma-2",
                    style: {"position":"absolute","top":"0","z-index":"999","background-color":"rgba(0,0,0,.25)","color":"white"}
                  }, {
                    default: _withCtx(() => [
                      _cache[10] || (_cache[10] = _createElementVNode("b", null, "Left Camera", -1)),
                      _createVNode(_component_v_icon, {
                        class: "pl-2",
                        color: "white",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openInNewWindow(_ctx.focusedImageSet.leftImage.s3Url)))
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("mdi-open-in-new")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.focusedImageSet.centerImage !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  ($options.imageRotated(_ctx.focusedImageSet.centerImage))
                    ? (_openBlock(), _createBlock(_component_v_img, {
                        key: 0,
                        contain: "",
                        src: _ctx.focusedImageSet.centerImage.s3Url,
                        "min-width": "200px",
                        "max-height": "280",
                        "max-width": "300",
                        style: _normalizeStyle({ transform: 'rotate('+ _ctx.centerRotate +'turn)'})
                      }, null, 8, ["src", "style"]))
                    : (_openBlock(), _createBlock(_component_v_img, {
                        key: 1,
                        contain: "",
                        src: _ctx.focusedImageSet.centerImage.s3Url,
                        "min-width": "200px",
                        "max-height": "280",
                        "max-width": "300"
                      }, null, 8, ["src"])),
                  _createVNode(_component_v_chip, {
                    class: "ma-2",
                    style: {"position":"absolute","top":"0","z-index":"999","background-color":"rgba(0,0,0,.25)","color":"white"}
                  }, {
                    default: _withCtx(() => [
                      _cache[12] || (_cache[12] = _createElementVNode("b", null, "Center Camera", -1)),
                      _createVNode(_component_v_icon, {
                        class: "pl-2",
                        color: "white",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.openInNewWindow(_ctx.focusedImageSet.centerImage.s3Url)))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("mdi-open-in-new")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.focusedImageSet.rightImage !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  ($options.imageRotated(_ctx.focusedImageSet.rightImage))
                    ? (_openBlock(), _createBlock(_component_v_img, {
                        key: 0,
                        class: "mr-12",
                        contain: "",
                        src: _ctx.focusedImageSet.rightImage.s3Url,
                        "max-height": "280",
                        "max-width": _ctx.sideCameraWidth,
                        style: _normalizeStyle({ transform: 'rotate('+ _ctx.rightRotate +'turn)'})
                      }, null, 8, ["src", "max-width", "style"]))
                    : (_openBlock(), _createBlock(_component_v_img, {
                        key: 1,
                        class: "mr-12",
                        contain: "",
                        src: _ctx.focusedImageSet.rightImage.s3Url,
                        "min-width": "200px",
                        "max-height": _ctx.sideCameraHeight,
                        "max-width": _ctx.sideCameraWidth
                      }, null, 8, ["src", "max-height", "max-width"])),
                  _createVNode(_component_v_chip, {
                    class: "ma-2",
                    style: {"position":"absolute","top":"0","z-index":"999","background-color":"rgba(0,0,0,.25)","color":"white"}
                  }, {
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createElementVNode("b", null, "Right Camera", -1)),
                      _createVNode(_component_v_icon, {
                        class: "pl-2",
                        color: "white",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.openInNewWindow(_ctx.focusedImageSet.rightImage.s3Url)))
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("mdi-open-in-new")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}