import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-0 ma-0",
  id: "home"
}
const _hoisted_2 = { style: {"overflow-y":"hidden !important"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_ScanList = _resolveComponent("ScanList")!
  const _component_MapView = _resolveComponent("MapView")!
  const _component_PointCloudView = _resolveComponent("PointCloudView")!
  const _component_ImageView = _resolveComponent("ImageView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar, {
      color: "grey-darken-3",
      dark: "",
      density: "compact",
      style: {"box-shadow":"none"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar_title, {
          style: {"width":"100%"},
          dark: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, {
              class: "pa-0 d-flex align-center",
              fluid: ""
            }, {
              default: _withCtx(() => [
                (!_ctx.showSidebar)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      icon: "",
                      tile: "",
                      density: "compact",
                      small: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSidebar = true))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("mdi-format-list-group mdi-rotate-180")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showSidebar)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      icon: "",
                      tile: "",
                      density: "compact",
                      small: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSidebar = false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("mdi-format-list-group")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_btn_toggle, {
                  class: "ml-auto",
                  modelValue: _ctx.display,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.display) = $event)),
                  mandatory: "",
                  style: {"height":"fit-content"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tooltip, {
                      location: "bottom",
                      "open-delay": 700
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({
                          icon: "",
                          small: "",
                          density: "compact",
                          style: {"color":"white","background-color":"transparent"}
                        }, props), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("mdi-compass")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("Show map view")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_tooltip, {
                      location: "bottom",
                      "open-delay": 700
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({
                          icon: "",
                          small: "",
                          density: "compact",
                          style: {"color":"white","background-color":"transparent"}
                        }, props), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("mdi-cube-scan")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Show point cloud view")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, {
      class: "ma-0 pa-0 d-flex",
      fluid: "",
      style: {"position":"relative","overflow-y":"hidden !important"}
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ScanList)
        ], 512), [
          [_vShow, _ctx.showSidebar]
        ]),
        _createVNode(_component_v_container, {
          class: "ma-0 pa-0",
          fluid: "",
          style: {"position":"relative","height":"90vh","width":"47vw","overflow":"hidden"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MapView, {
              class: _normalizeClass({ active: $options.displayMap })
            }, null, 8, ["class"]),
            _createVNode(_component_PointCloudView, {
              class: _normalizeClass({ active: $options.displayPointCloud }),
              showFullMenu: ""
            }, null, 8, ["class"]),
            _createVNode(_component_ImageView)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}