import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { id: "activity-log-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = { style: {"width":"100%","padding":"3vh"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_subheader = _resolveComponent("v-subheader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (($data.totalActivityLogs === 0) && $props.hideOnNoValues)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          type: "info"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString($props.noValuesAlertMessage), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_data_table, {
          key: 1,
          headers: $options.headers,
          options: $data.options,
          items: $data.activityLogItems,
          "server-items-length": $data.totalActivityLogs,
          loading: $options.loading,
          "loading-text": "Loading... Please wait",
          "footer-props": { 'items-per-page-options': [10,25,50] },
          "show-expand": ""
        }, {
          "item.lastModified": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.lastModified)), 1)
          ]),
          "item.submitted": _withCtx(({ item }) => [
            (item.submitted === null)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.created)), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.submitted)), 1)
                ], 64))
          ]),
          "item.started": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.started)), 1)
          ]),
          "item.completed": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.completed)), 1)
          ]),
          "item.logFileUrl": _withCtx(({ item }) => [
            (item.logFileUrl)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  onClick: ($event: any) => ($options.showLogFile(item.logFileUrl, item.id)),
                  flat: ""
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("mdi-text-box-search-outline")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          "item.status": _withCtx(({ item }) => [
            (item.status === 'Event')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 0,
                  class: "ma-2",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Event")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (item.status === 'Submitted')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 1,
                  class: "ma-2",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Submitted")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (item.status === 'Started')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 2,
                  class: "ma-2",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Started")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (item.status === 'Completed')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 3,
                  class: "ma-2",
                  color: "green",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Completed")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (item.status === 'Failed')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 4,
                  class: "ma-2",
                  color: "red",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Failed")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (item.status === 'Error')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 5,
                  class: "ma-2",
                  color: "red",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Error")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (item.status === 'Hung')
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 6,
                  class: "ma-2",
                  color: "red",
                  small: ""
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("Hung")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          "item.isAcknowledged": _withCtx(({ item }) => [
            _createVNode(_component_v_checkbox, {
              class: "ma-2",
              modelValue: item.isAcknowledged,
              "onUpdate:modelValue": ($event: any) => ((item.isAcknowledged) = $event),
              onChange: ($event: any) => ($options.acknowledge(item, item.isAcknowledged))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
          ]),
          "item.actions": _withCtx(({ item }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.itemActions, (itemAction) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (itemAction.icon)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      icon: "",
                      onClick: ($event: any) => (itemAction.click(item)),
                      flat: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { small: "" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(itemAction.icon), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      size: "x-small",
                      onClick: ($event: any) => (itemAction.click(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(itemAction.text), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
              ], 64))
            }), 256))
          ]),
          "expanded-row": _withCtx(({ item }) => [
            (item.status !== 'Event')
              ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
                  _createElementVNode("td", {
                    colspan: $options.headers.length
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_v_subheader, null, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Payload")
                        ])),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.jsonifyAndMap(item?.payload), (payloadItem) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: payloadItem.key,
                          style: {"display":"flex","flex-direction":"row","gap":"1vw","padding-top":"1vh","padding-left":"1vw"}
                        }, [
                          _createElementVNode("div", null, _toDisplayString(payloadItem?.key) + ":", 1),
                          _createElementVNode("div", null, _toDisplayString(payloadItem?.value), 1)
                        ]))
                      }), 128)),
                      _createVNode(_component_v_subheader, { style: {"margin-top":"2vh"} }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("Metrics")
                        ])),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.jsonifyAndMap(item?.metrics), (metricsItem) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: metricsItem.key,
                          style: {"display":"flex","flex-direction":"row","gap":"1vw","padding-top":"1vh","padding-left":"1vw"}
                        }, [
                          _createElementVNode("div", null, _toDisplayString(metricsItem?.key) + ":", 1),
                          _createElementVNode("div", null, _toDisplayString(metricsItem?.key === 'fileSize' ? $options.formatBytes(metricsItem?.value) : metricsItem?.value), 1)
                        ]))
                      }), 128))
                    ])
                  ], 8, _hoisted_3)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "options", "items", "server-items-length", "loading"])),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: $data.dialogLogFile,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.dialogLogFile) = $event)),
      "max-width": "80vw"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Log results for " + _toDisplayString($data.dialogLogFileTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_textarea, {
                  counter: "",
                  density: "compact",
                  flat: "",
                  rows: "20",
                  "row-height": "22",
                  readonly: "",
                  value: $data.logFile,
                  style: {"font-family":"'Courier New'","font-size":"14px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { "hide-details": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.dialogLogFile = false))
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Close")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}