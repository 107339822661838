import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: $data.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.show) = $event)),
    color: $data.color,
    timeout: $data.timeout,
    "multi-line": "",
    top: ""
  }, {
    action: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps({
        dark: "",
        text: ""
      }, props, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.show = false))
      }), {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Close")
        ])),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($data.message), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "color", "timeout"]))
}