import { Store, createStore } from "vuex";

import { ActivityLogger } from "../services/activity-logger";
import { GET_USER_CONTEXT } from "../graphql/org-user-queries";
import { MAPSTYLES } from "@/contants";
import { apolloClient } from "../services/vue-apollo";
import fileUploadStoreModule from "./file-upload-store";
import { getRouter } from "../router";
import viewerStoreModule from "./viewer-store";

export interface RootState {
  loading: number;
  loggedIn: boolean;
  username: string | null;
  awsUserId: string | null;
  user: any;
  selectedMapStyle: string;
  snackbar: {
    content: string;
    color: string;
    timeout: number;
  };
}

const mutations = {
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_LOGGED_OUT: "SEE_LOGGED_OUT",
};

const store = createStore<RootState>({
  modules: {
    fileUpload: fileUploadStoreModule,
    viewer: viewerStoreModule,
  },

  state() {
    return {
      loading: 0,
      loggedIn: false,
      username: null,
      awsUserId: null,
      user: null,
      selectedMapStyle: "satellite streets",
      snackbar: {
        content: "",
        color: "",
        timeout: 0,
      },
    };
  },

  mutations: {
    showMessage(
      state,
      payload: { content: string; color: string; timeout?: number }
    ) {
      state.snackbar.content = payload.content;
      state.snackbar.color = payload.color;
      state.snackbar.timeout = payload.timeout ?? 8000;
    },
    [mutations.SET_LOGGED_IN](state) {
      state.loggedIn = true;
    },
    [mutations.SET_LOGGED_OUT](state) {
      state.loggedIn = false;
    },
    startLoading(state) {
      state.loading += 1;
    },
    stopLoading(state) {
      state.loading -= 1;
    },
    setUsername(state, username: string | null) {
      state.username = username;
    },
    setUser(state, user: any) {
      state.user = user;
    },
    setAwsUserId(state, awsUserId: string | null) {
      state.awsUserId = awsUserId;
    },
    setMapStyle(state, mapStyle: string) {
      state.selectedMapStyle = mapStyle;
    },
  },

  actions: {
    setMapStyle({ commit }, style: string) {
      commit("setMapStyle", style);
    },
    setLoggedIn({ commit, dispatch }, cognitoUser) {
      const activityLogger = new ActivityLogger();
      activityLogger.logEvent("UserLogin");

      commit("setUsername", cognitoUser.username);
      commit("setAwsUserId", cognitoUser.sub);
      dispatch("refreshUserContext");
    },
    setLoggedOut({ commit }) {
      commit(mutations.SET_LOGGED_OUT);
      commit("setUsername", null);
      commit("setAwsUserId", null);
      commit("setUser", null);
    },
    refreshUserContext({ commit, state, dispatch }) {
      commit("startLoading");
      apolloClient
        .query({
          query: GET_USER_CONTEXT,
          variables: { awsUserId: state.awsUserId },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data.user && res.data.user.enabled) {
            commit("setUser", res.data.user);
            commit(mutations.SET_LOGGED_IN);
          } else {
            dispatch("setLoggedOut");
            getRouter().push({ name: "Unauthorized" });
          }
        })
        .catch((err) => {
          console.log("error loading user context");
          console.log(err);
          dispatch("setLoggedOut");
        })
        .finally(() => {
          commit("stopLoading");
        });
    },
  },

  getters: {
    user: (state) => state.user,
    isLoading: (state) => state.loading > 0,
    loading: (state) => state.loading,
    loggedIn: (state) => state.loggedIn,
    mapStyleNames: () => {
      return MAPSTYLES.map((x) => x[0]);
    },
    selectedMapStyle: (state) => state.selectedMapStyle,
    selectedMapStyleURL: (state) => {
      const found = MAPSTYLES.find((x) => x[0] === state.selectedMapStyle);
      return found ? found[1] : "";
    },
    canProcess: (state) => {
      return (
        state.user?.userType === "Admin" ||
        state.user?.userType === "Power User"
      );
    },
    isPowerUser: (state) => {
      return state.user?.userType === "Power User";
    },
  },
});

export default store;
