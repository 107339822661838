/* eslint-disable */
// src/services/auth.ts

import { CognitoAuth, StorageHelper } from "amazon-cognito-auth-js";
import { removeApolloAuthToken, setApolloAuthToken } from "./vue-apollo";

import { apolloProvider } from "./vue-apollo"; // Ensure you have access to the Apollo client
import axios from "axios";
import { getGlobalConfig } from "../config/config.js"; // Your config getter
import { getRouter } from "../router"; // Access your router instance
import store from "../store/store";

// A global variable to hold our exchanged tokens
let cognitoSession: any = null;

// We still initialize CognitoAuth to hold configuration values
let awsAuthService: CognitoAuth | null = null;

let redirectSignIn = "";
let redirectSignOut = "";

/**
 * Initializes CognitoAuth with the loaded configuration.
 * Note: We add the `ResponseType: "code"` property so that the Hosted UI
 * uses the authorization code grant flow.
 */
export const initializeAuth = () => {
  const config = getGlobalConfig();

  if (!config?.domain_name) {
    throw new Error("Cognito configuration is missing!");
  }

  const redirectSignInArray = config.redirect_sign_in.split(",");
  const redirectSignOutArray = config.redirect_sign_out.split(",");

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    redirectSignIn = redirectSignInArray.find((url) =>
      url.includes("localhost")
    );
    redirectSignOut = redirectSignOutArray.find((url) =>
      url.includes("localhost")
    );
  } else {
    redirectSignIn = redirectSignInArray.find(
      (url) => !url.includes("localhost")
    );
    redirectSignOut = redirectSignOutArray.find(
      (url) => !url.includes("localhost")
    );
  }

  const authConfig = {
    ClientId: config.aws_user_pools_web_client_id,
    AppWebDomain: config.oauth_domain,
    TokenScopesArray: config.supported_logins.split(","),
    RedirectUriSignIn: redirectSignIn,
    RedirectUriSignOut: redirectSignOut,
    UserPoolId: config.aws_user_pools_id,
    ResponseType: "code",
  };

  console.log("auth config", authConfig);

  awsAuthService = new CognitoAuth(authConfig);

  // Optional: define userhandler callbacks to centralize post-login/-failure logic.
  awsAuthService.userhandler = {
    onSuccess() {
      console.log("Authorization code flow succeeded – navigating to /");
      try {
        getRouter().push("/");
      } catch (error) {
        console.error("Router instance is not available:", error);
      }
    },
    onFailure(err) {
      console.error(
        "Authentication failed – removing Apollo auth token, updating store, and redirecting to login",
        err
      );
      removeApolloAuthToken(apolloProvider.defaultClient);
      store.dispatch("setLoggedOut");
      try {
        getRouter().push("/login");
      } catch (error) {
        console.error("Router instance is not available:", error);
      }
    },
  };
};

/**
 * Exchanges the authorization code for tokens via Cognito’s /oauth2/token endpoint.
 * @param code The authorization code returned from Cognito.
 */
async function exchangeAuthorizationCode(code: string) {
  const config = getGlobalConfig();
  const tokenUrl = `https://${config.oauth_domain}/oauth2/token`;

  // Prepare URL-encoded body parameters
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", config.aws_user_pools_web_client_id);
  params.append("code", code);
  params.append("redirect_uri", redirectSignIn);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // If using a client secret, include the appropriate Authorization header here.
    // Example (if needed):
    // "Authorization": "Basic " + btoa(`${config.aws_user_pools_web_client_id}:${config.client_secret}`)
  };

  try {
    const response = await axios.post(tokenUrl, params, { headers });
    return response.data; // Expecting an object with access_token, id_token, etc.
  } catch (error) {
    throw new Error("Failed to exchange authorization code: " + error);
  }
}

/**
 * Parses the current URL for an authorization code.
 * If found, exchanges it for tokens and updates Apollo, store, etc.
 * @param url The full URL (typically window.location.href).
 */
export async function parseCognitoWebResponse(url: string) {
  if (!awsAuthService) {
    throw new Error("Auth service not initialized.");
  }
  const urlObj = new URL(url);
  const error = urlObj.searchParams.get("error");
  if (error) {
    const errorDescription = urlObj.searchParams.get("error_description");
    const errMsg = `OAuth error: ${error}. Description: ${errorDescription}`;
    console.error(errMsg);
    awsAuthService.userhandler.onFailure(new Error(errMsg));
    return;
  }
  const code = urlObj.searchParams.get("code");
  if (code) {
    try {
      // Exchange the code for tokens
      const tokenResponse = await exchangeAuthorizationCode(code);
      // Save the tokens in our global variable and persist them in storage
      cognitoSession = tokenResponse;
      const storageHelper = new StorageHelper();
      const storage = storageHelper.getStorage();
      storage.setItem("cognito_tokens", JSON.stringify(tokenResponse));

      // Set the Apollo auth token using the received access token
      await setApolloAuthToken(
        apolloProvider.defaultClient,
        tokenResponse.access_token
      );

      // Optionally fetch the user info from Cognito's /oauth2/userInfo endpoint
      const userInfoUrl = `https://${awsAuthService.getAppWebDomain()}/oauth2/userInfo`;
      const userInfoResponse = await axios.get(userInfoUrl, {
        headers: { Authorization: "Bearer " + tokenResponse.access_token },
      });
      store.dispatch("setLoggedIn", userInfoResponse.data);

      // Call the success handler
      awsAuthService.userhandler.onSuccess();
    } catch (err) {
      console.error("Error exchanging authorization code:", err);
      awsAuthService.userhandler.onFailure(err);
    }
  } else {
    console.log("No authorization code found in the URL.");
  }
}

/**
 * Retrieves the JWT access token from the stored session.
 * Throws an error if the token is unavailable.
 */
export function getJwtToken(): string {
  if (cognitoSession && cognitoSession.access_token) {
    return cognitoSession.access_token;
  }
  // Try to load from persistent storage
  const storageHelper = new StorageHelper();
  const storage = storageHelper.getStorage();
  const stored = storage.getItem("cognito_tokens");
  if (stored) {
    cognitoSession = JSON.parse(stored);
    return cognitoSession.access_token;
  }
  throw new Error("User session is not available.");
}

/**
 * Returns the HTTP Authorization header for requests.
 */
export function getAuthHeader() {
  return {
    headers: {
      Authorization: "Bearer " + getJwtToken(),
    },
  };
}

/**
 * Fetches the authenticated user’s info from Cognito.
 * Dispatches a login action if not already logged in.
 */
async function getAuthenticatedUser(): Promise<string> {
  const token = getJwtToken();
  const config = getGlobalConfig();
  const userInfoUrl = `https://${
    awsAuthService ? awsAuthService.getAppWebDomain() : config.oauth_domain
  }/oauth2/userInfo`;
  try {
    const response = await axios.get(userInfoUrl, {
      headers: { Authorization: "Bearer " + token },
    });
    if (!store.getters.loggedIn) {
      await setApolloAuthToken(apolloProvider.defaultClient, token);
      store.dispatch("setLoggedIn", response.data);
    }
    // Adjust the property name as returned by your Cognito userInfo endpoint
    return response.data.username || response.data.preferred_username || "";
  } catch (error) {
    console.error("Error fetching authenticated user info:", error);
    throw error;
  }
}

/**
 * Initiates the Cognito sign-in process by redirecting to the Hosted UI.
 * (Here we manually construct the URL with response_type=code.)
 */
function login() {
  if (!awsAuthService) {
    throw new Error("Auth service not initialized.");
  }
  const config = getGlobalConfig();
  // Note: The `scope` value may need to be space-delimited per OAuth spec.
  const scopes = config.supported_logins?.replace(/,/g, " ");
  const loginUrl = `https://${
    config.oauth_domain
  }/login?response_type=code&client_id=${
    config.aws_user_pools_web_client_id
  }&redirect_uri=${encodeURIComponent(
    redirectSignIn
  )}&scope=${encodeURIComponent(scopes)}`;
  window.location.href = loginUrl;
}

/**
 * Logs out the user by clearing tokens, updating the store,
 * and redirecting to Cognito’s logout URL.
 */
function logout() {
  store.dispatch("setLoggedOut");
  removeApolloAuthToken(apolloProvider.defaultClient);
  const storageHelper = new StorageHelper();
  const storage = storageHelper.getStorage();
  storage.removeItem("cognito_tokens");

  if (awsAuthService) {
    const config = getGlobalConfig();
    // Construct the logout URL – note the parameters required by your Cognito setup.
    const logoutUrl = `https://${config.oauth_domain}/logout?client_id=${
      config.aws_user_pools_web_client_id
    }&logout_uri=${encodeURIComponent(redirectSignOut)}`;
    window.location.href = logoutUrl;
  }
}

// Export the functions as part of the default service API
export default {
  login,
  logout,
  getAuthenticatedUser,
  parseCognitoWebResponse,
};
