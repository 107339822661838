import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client/core";

import Vue from "vue";
import VueApollo from "vue-apollo";
import { onError } from "@apollo/client/link/error";
import store from "@/store/store";

const AUTH_TOKEN = "luxweb";
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP;
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      store.commit("showMessage", {
        content: message,
        color: "red",
        timeout: 6000,
      });
    });
  }
});

const httpLink = new HttpLink({ uri: httpEndpoint, fetch });

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});

export async function setApolloAuthToken(client, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  // if (client) {
  //   try {
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // }
}

export async function removeApolloAuthToken(client) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  // if (client) {
  //   try {
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // }
}

export { apolloClient };
