import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"overflow-y":"hidden"} }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_ScanFilter = _resolveComponent("ScanFilter")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_virtual_scroll = _resolveComponent("v-virtual-scroll")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_navigation_drawer, {
      ref: "drawer",
      style: {"overflow-y":"hidden !important"},
      location: "left",
      width: $data.width
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs, {
          "bg-color": "grey-lighten-2",
          color: "blue",
          "fixed-tabs": "",
          height: "35",
          modelValue: $data.selectedTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedTab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, null, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createTextVNode("Scans")),
                (_ctx.summaryScanList.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_badge, {
                      key: 0,
                      color: "blue",
                      content: _ctx.summaryScanList.length
                    }, null, 8, ["content"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_tab, {
              disabled: !_ctx.someScanSelected || $options.annotationsByScanList.length === 0
            }, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createTextVNode("Annotations")),
                ($options.annotationsByScanList && $options.annotationsByScanList.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_badge, {
                      key: 0,
                      color: "blue",
                      content: $options.annotationsByScanList.length
                    }, null, 8, ["content"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_v_tab, {
              disabled: !_ctx.projectLayerList.length > 0
            }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createTextVNode("Layers")),
                (_ctx.projectLayerList.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_badge, {
                      key: 0,
                      color: "blue",
                      content: _ctx.projectLayerList.length
                    }, null, 8, ["content"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        ($data.selectedTab === 0)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 0,
              class: "pa-0 ma-0",
              style: {"overflow-y":"hidden !important"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ScanFilter, { enableSelect: "true" }),
                _createVNode(_component_v_card_text, { style: {"height":"calc(100vh - 316px)","overflow":"hidden","padding":"0px"} }, {
                  default: _withCtx(() => [
                    (_ctx.isLoading && _ctx.filteredSummaryScanList.length === 0)
                      ? (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                          key: 0,
                          type: "list-item-avatar-three-line",
                          tile: "",
                          outlined: ""
                        }))
                      : (_openBlock(), _createBlock(_component_v_virtual_scroll, {
                          bench: 15,
                          key: _ctx.filteredSummaryScanList.length,
                          items: _ctx.filteredSummaryScanList,
                          "item-size": "175",
                          height: "100vh"
                        }, {
                          default: _withCtx(({ item }) => [
                            (_openBlock(), _createBlock(_component_v_card, {
                              class: "d-flex pa-0",
                              outlined: "",
                              tile: "",
                              key: item.id,
                              height: "175"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, {
                                  class: _normalizeClass(["no-gutters", { focused: $options.isScanFocused(item.id), selected: _ctx.isScanSelected(item.id) }]),
                                  style: {"flex-wrap":"nowrap"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "ma-2 flex-grow-1 flex-shrink-0" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item, {
                                          class: "ma-0 pa-0 d-block",
                                          "three-line": ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("b", null, _toDisplayString(_ctx.getScanName(item)), 1),
                                            _cache[28] || (_cache[28] = _createTextVNode()),
                                            (item.length > 0)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.$filters.asFloat(item.length)) + " meters)", 1))
                                              : _createCommentVNode("", true),
                                            ($options.importedScan(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 1,
                                                  location: "bottom"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({
                                                      class: "pl-1",
                                                      color: "transparent"
                                                    }, props, {
                                                      size: "x-small",
                                                      icon: "",
                                                      flat: ""
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, null, {
                                                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                                                            _createTextVNode("mdi-cloud-upload-outline")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _cache[5] || (_cache[5] = _createElementVNode("b", null, "Imported LAS point cloud", -1)),
                                                        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                                                        _cache[7] || (_cache[7] = _createTextVNode("(not processed from LuxGear) ")),
                                                        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Scan Id: " + _toDisplayString(item.id), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true),
                                            (!$options.importedScan(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 2,
                                                  location: "bottom"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      onClick: ($event: any) => ($options.openDialog(item)),
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, null, {
                                                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                            _createTextVNode("mdi-information-outline")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040, ["onClick"])
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _cache[10] || (_cache[10] = _createElementVNode("b", null, "LuxGear LAS point cloud", -1)),
                                                        _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Scan Id: " + _toDisplayString(item.id), 1),
                                                        _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Session Id: " + _toDisplayString(item.sessionId), 1),
                                                        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Trajectory Orientation: " + _toDisplayString($options.parseOrientation(item.metadata).trajectory_orientation), 1),
                                                        _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Device Orientation: " + _toDisplayString($options.parseOrientation(item.metadata).device_orientation), 1),
                                                        _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Device Position: " + _toDisplayString($options.parseOrientation(item.metadata).device_position), 1),
                                                        _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                                                        _createTextVNode("Last Processed: " + _toDisplayString(_ctx.$filters.friendlyTime(item.lastModified)), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true),
                                            ($options.scanHasImages(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 3,
                                                  location: "bottom"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, null, {
                                                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                            _createTextVNode("mdi-camera")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode("Scan includes images for " + _toDisplayString($options.scanImageList(item)), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true),
                                            ($options.scanHasAnnotations(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 4,
                                                  location: "bottom"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, null, {
                                                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                            _createTextVNode("mdi-comment-text-outline ")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                        _createTextVNode("Scan includes annotations ")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            (!$options.importedScan(item) && $options.scanStatusFinal(item) && $options.scanHasPointCloud(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 5,
                                                  location: "bottom",
                                                  color: "green"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, {
                                                          color: "green",
                                                          flat: ""
                                                        }, {
                                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                            _createTextVNode("mdi-checkbox-marked-circle")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                        _createTextVNode("Scan successfully processed to final")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            (!$options.importedScan(item) && !$options.scanStatusFinal(item) && $options.scanHasPointCloud(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 6,
                                                  location: "bottom",
                                                  color: "warning"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, {
                                                          color: "warning",
                                                          flat: ""
                                                        }, {
                                                          default: _withCtx(() => _cache[22] || (_cache[22] = [
                                                            _createTextVNode("mdi-alert")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                        _createTextVNode("Scan processed with provisional trajectory, base station data is required for final processing.")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            (!item.simplePath)
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 7,
                                                  location: "bottom",
                                                  color: "warning"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, {
                                                          color: "warning",
                                                          flat: ""
                                                        }, {
                                                          default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                            _createTextVNode("mdi-alert")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                        _createTextVNode("Scan is missing a path and cannot be rendered on the map view")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            (!$options.scanHasPointCloud(item))
                                              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                  key: 8,
                                                  location: "bottom",
                                                  color: "red"
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_btn, _mergeProps({ class: "pl-1" }, props, {
                                                      density: "compact",
                                                      size: "small",
                                                      icon: "",
                                                      flat: "",
                                                      color: "transparent"
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, {
                                                          color: "red",
                                                          flat: ""
                                                        }, {
                                                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                            _createTextVNode("mdi-alert-octagon")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_container, {
                                                      class: "pa-0 ma-0",
                                                      width: "150px"
                                                    }, {
                                                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                                                        _createTextVNode("\"Scan's point cloud is not available (it may still be in processing)\"")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            _createVNode(_component_v_list_item_subtitle, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString($options.getFriendlyLocationName(item)), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            (item.capturedTimestamp)
                                              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 9 }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$filters.friendlyTime(item.capturedTimestamp)) + " (" + _toDisplayString(_ctx.$filters.absoluteTime(item.capturedTimestamp)) + ") ", 1)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true),
                                            _createVNode(_component_v_text_field, {
                                              class: "mt-1",
                                              placeholder: "click to add comments",
                                              value: $data.editedItem[item.id] || item.comment,
                                              density: "compact",
                                              filled: "",
                                              flat: "",
                                              "hide-details": "",
                                              maxlength: "60",
                                              "onUpdate:modelValue": ($event: any) => ($options.updateScanComment($event, item))
                                            }, null, 8, ["value", "onUpdate:modelValue"]),
                                            _createVNode(_component_v_combobox, {
                                              class: "mt-1",
                                              placeholder: "click to add tags",
                                              multiple: "",
                                              value: item.tags,
                                              "small-chips": "",
                                              "hide-details": "",
                                              density: "compact",
                                              filled: "",
                                              flat: "",
                                              "deletable-chips": "",
                                              items: $options.tagOptions,
                                              "onUpdate:modelValue": ($event: any) => ($options.updateScanTags($event, item))
                                            }, null, 8, ["value", "items", "onUpdate:modelValue"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { class: "ma-4 flex-grow-0" }, {
                                      default: _withCtx(() => [
                                        (!_ctx.isScanSelected(item.id))
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              color: "transparent",
                                              icon: "",
                                              disabled: !$options.scanHasPointCloud(item),
                                              onClick: ($event: any) => ($options.selectScan(item.id)),
                                              flat: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                                    _createTextVNode("mdi-checkbox-blank-outline")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["disabled", "onClick"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.isScanSelected(item.id))
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 1,
                                              color: "transparent",
                                              icon: "",
                                              onClick: ($event: any) => ($options.unselectScan(item.id)),
                                              flat: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                                    _createTextVNode("mdi-checkbox-marked")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.isScanSelected(item.id) && _ctx.isScanLoaded(item.id) && !_ctx.isScanVisible(item.id))
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 2,
                                              color: "transparent",
                                              icon: "",
                                              onClick: ($event: any) => ($options.showScan(item.id)),
                                              flat: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                                    _createTextVNode("mdi-eye-plus-outline")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.isScanSelected(item.id) && _ctx.isScanLoaded(item.id) && _ctx.isScanVisible(item.id))
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 3,
                                              color: "transparent",
                                              icon: "",
                                              onClick: ($event: any) => ($options.hideScan(item.id)),
                                              flat: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                                                    _createTextVNode("mdi-eye-remove")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.isScanLoaded(item.id) && _ctx.isScanVisible(item.id))
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 4,
                                              color: "transparent",
                                              icon: "",
                                              onClick: ($event: any) => ($options.focusScan(item.id)),
                                              flat: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                                    _createTextVNode("mdi-image-filter-center-focus")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["class"])
                              ]),
                              _: 2
                            }, 1024))
                          ]),
                          _: 1
                        }, 8, ["items"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($data.selectedTab === 1)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 1,
              class: "pa-0 ma-0 diplay-flex flex-column"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  class: "no-gutters",
                  style: {"flex-wrap":"nowrap"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      class: "ma-2",
                      cols: "10"
                    }, {
                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                        _createElementVNode("span", null, "Show All", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      class: "ma-1",
                      cols: "2"
                    }, {
                      default: _withCtx(() => [
                        (!$data.showAll)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              icon: "",
                              onClick: $options.selectAllAnnotations,
                              flat: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { flat: "" }, {
                                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                                    _createTextVNode("mdi-checkbox-blank-outline")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        ($data.showAll)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 1,
                              icon: "",
                              onClick: $options.unselectAllAnnotations,
                              flat: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { flat: "" }, {
                                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                                    _createTextVNode("mdi-checkbox-marked")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($data.selectedTab === 1)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 2,
              class: "pa-0 ma-0 diplay-flex flex-column",
              style: {"height":"calc(100vh - 185px)"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_virtual_scroll, {
                  bench: 15,
                  items: $options.annotationsByScanList,
                  "item-height": "150",
                  height: "100%"
                }, {
                  default: _withCtx(({ item }) => [
                    (_openBlock(), _createBlock(_component_v_card, {
                      class: "d-flex pa-0",
                      outlined: "",
                      tile: "",
                      key: item.id,
                      height: "150"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          class: _normalizeClass(["no-gutters", { selected: _ctx.isAnnotationSelected(item.id) }]),
                          style: {"flex-wrap":"nowrap"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "ma-2 flex-grow-1 flex-shrink-0" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item, { class: "ma-0 pa-0 d-block" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, {
                                      class: "mt-1",
                                      modelValue: item.title,
                                      "onUpdate:modelValue": ($event: any) => ((item.title) = $event),
                                      dense: "",
                                      filled: "",
                                      flat: "",
                                      "hide-details": "",
                                      maxlength: "60"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_v_list_item_subtitle, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", null, _toDisplayString(item.scan.id), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_list_item_subtitle, { class: "text-caption" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", null, "X: " + _toDisplayString(item.point.coordinates[0].toFixed(3)) + " Y: " + _toDisplayString(item.point.coordinates[1].toFixed(3)) + " Z: " + _toDisplayString(item.point.coordinates[2].toFixed(3)), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_textarea, {
                                      class: "mt-1",
                                      modelValue: item.comment,
                                      "onUpdate:modelValue": ($event: any) => ((item.comment) = $event),
                                      dense: "",
                                      filled: "",
                                      flat: "",
                                      "hide-details": "",
                                      maxlength: "60",
                                      onChange: ($event: any) => ($options.editAnnotation(item)),
                                      rows: "2",
                                      "row-height": "20",
                                      "no-resize": ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { class: "ma-4 flex-grow-0" }, {
                              default: _withCtx(() => [
                                (!_ctx.isAnnotationSelected(item.id))
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      icon: "",
                                      onClick: ($event: any) => ($options.selectAnnotation(item)),
                                      flat: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { flat: "" }, {
                                          default: _withCtx(() => _cache[37] || (_cache[37] = [
                                            _createTextVNode("mdi-checkbox-blank-outline")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.isAnnotationSelected(item.id))
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 1,
                                      icon: "",
                                      onClick: ($event: any) => ($options.unselectAnnotation(item)),
                                      flat: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { flat: "" }, {
                                          default: _withCtx(() => _cache[38] || (_cache[38] = [
                                            _createTextVNode("mdi-checkbox-marked")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_v_btn, {
                                  icon: "",
                                  onClick: ($event: any) => ($options.zoomToAnnotation(item))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, { flat: "" }, {
                                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                                        _createTextVNode("mdi-image-filter-center-focus")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_v_btn, {
                                  icon: "",
                                  onClick: ($event: any) => ($options.deleteAnnotation(item))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, { flat: "" }, {
                                      default: _withCtx(() => _cache[40] || (_cache[40] = [
                                        _createTextVNode("mdi-delete ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["class"])
                      ]),
                      _: 2
                    }, 1024))
                  ]),
                  _: 1
                }, 8, ["items"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($data.selectedTab === 2)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 3,
              class: "pa-0 ma-0 diplay-flex flex-column"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, { style: {"height":"calc(100vh - 100px)","overflow":"hidden","padding":"0px 0px 0px 0px"} }, {
                  default: _withCtx(() => [
                    (_ctx.isLoading)
                      ? (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                          key: 0,
                          type: "list-item-avatar-three-line",
                          tile: "",
                          outlined: ""
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_virtual_scroll, {
                      bench: 15,
                      items: _ctx.projectLayerList,
                      "item-height": "80",
                      height: "100%"
                    }, {
                      default: _withCtx(({ item }) => [
                        (_openBlock(), _createBlock(_component_v_card, {
                          class: "d-flex pa-0",
                          outlined: "",
                          tile: "",
                          key: item.id,
                          height: "80"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, {
                              class: _normalizeClass(["no-gutters", { active: _ctx.isProjectLayerSelected(item.id) }]),
                              style: {"flex-wrap":"nowrap"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { class: "ma-2 flex-grow-1 flex-shrink-0" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item, {
                                      class: "ma-0 pa-0 d-block",
                                      "two-line": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("b", null, _toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_list_item_subtitle, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.comments), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, { class: "ma-4 flex-shrink-1 flex-grow-0" }, {
                                  default: _withCtx(() => [
                                    (!_ctx.isProjectLayerSelected(item.id))
                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                          key: 0,
                                          icon: "",
                                          onClick: ($event: any) => ($options.selectProjectLayer(item.id)),
                                          flat: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, { flat: "" }, {
                                              default: _withCtx(() => _cache[41] || (_cache[41] = [
                                                _createTextVNode("mdi-checkbox-blank-outline")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.isProjectLayerSelected(item.id))
                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                          key: 1,
                                          icon: "",
                                          onClick: ($event: any) => ($options.unselectProjectLayer(item.id)),
                                          flat: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, { flat: "" }, {
                                              default: _withCtx(() => _cache[42] || (_cache[42] = [
                                                _createTextVNode("mdi-checkbox-marked")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["class"])
                          ]),
                          _: 2
                        }, 1024))
                      ]),
                      _: 1
                    }, 8, ["items"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["width"])
  ]))
}