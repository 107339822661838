import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-0 ma-0 flex",
  id: "trajectory-processing-run"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.getStatusCount($props.trajectoryProcessingRuns, 'Submitted') > 0)
      ? (_openBlock(), _createBlock(_component_v_chip, {
          key: 0,
          class: "ma-2",
          small: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Submitted: " + _toDisplayString($options.getStatusCount($props.trajectoryProcessingRuns, 'Submitted')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($options.getStatusCount($props.trajectoryProcessingRuns, 'In progress') > 0)
      ? (_openBlock(), _createBlock(_component_v_chip, {
          key: 1,
          class: "ma-2",
          small: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("In Progress: " + _toDisplayString($options.getStatusCount($props.trajectoryProcessingRuns, 'In progress')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($options.getStatusCount($props.trajectoryProcessingRuns, 'Completed') > 0)
      ? (_openBlock(), _createBlock(_component_v_chip, {
          key: 2,
          class: "ma-2",
          color: "green",
          small: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Completed: " + _toDisplayString($options.getStatusCount($props.trajectoryProcessingRuns, 'Completed')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($options.getStatusCount($props.trajectoryProcessingRuns, 'Hung') > 0)
      ? (_openBlock(), _createBlock(_component_v_chip, {
          key: 3,
          class: "ma-2",
          color: "yellow",
          small: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Hung: " + _toDisplayString($options.getStatusCount($props.trajectoryProcessingRuns, 'Hung')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($options.getStatusCount($props.trajectoryProcessingRuns, 'Failed') > 0)
      ? (_openBlock(), _createBlock(_component_v_chip, {
          key: 4,
          class: "ma-2",
          color: "red",
          small: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Failed: " + _toDisplayString($options.getStatusCount($props.trajectoryProcessingRuns, 'Failed')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}