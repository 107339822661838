import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "logout-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.currentRouteName === 'Unauthorized')
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "ma-5",
          flat: ""
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("h2", null, "User account is not authorized.", -1),
            _createElementVNode("h4", null, [
              _createTextVNode("Please contact "),
              _createElementVNode("a", { href: `mailto:support@luxmodus.com` }, " support@luxmodus.com ")
            ], -1)
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($options.currentRouteName === 'Logout')
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 1,
          class: "ma-5",
          flat: ""
        }, {
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("h2", null, "You have been logged out", -1)),
            _createVNode(_component_router_link, { to: "/login" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Log in")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}