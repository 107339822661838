import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "scan-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, {
      class: "d-flex",
      flat: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, {
          class: "pa-2",
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              class: "no-gutters px-3 ma-0",
              style: {"flex-wrap":"nowrap"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  class: "ml-auto",
                  placeholder: "session ID search",
                  modelValue: _ctx.filterSessionId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterSessionId) = $event)),
                  density: "compact",
                  "hide-details": "",
                  maxlength: "29",
                  variant: "underlined"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_select, {
                  class: "ml-3",
                  items: _ctx.availableTypes,
                  placeholder: "type",
                  style: {"max-width":"110px"},
                  modelValue: _ctx.filterType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterType) = $event)),
                  density: "compact",
                  "hide-details": "",
                  variant: "underlined"
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, {
              class: "no-gutters px-3 ma-0",
              style: {"flex-wrap":"nowrap"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  class: "ml-auto",
                  placeholder: "scan ID search",
                  modelValue: _ctx.filterScanId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterScanId) = $event)),
                  density: "compact",
                  "hide-details": "",
                  maxlength: "26",
                  variant: "underlined"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_select, {
                  class: "ml-3",
                  items: _ctx.statusOptions,
                  placeholder: "status",
                  style: {"max-width":"110px"},
                  modelValue: _ctx.filterStatus,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterStatus) = $event)),
                  density: "compact",
                  "hide-details": "",
                  variant: "underlined"
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, {
              class: "no-gutters px-3 ma-0",
              style: {"flex-wrap":"nowrap"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  class: "ml-auto",
                  placeholder: "comment search",
                  modelValue: _ctx.filterComments,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterComments) = $event)),
                  density: "compact",
                  "hide-details": "",
                  maxlength: "60",
                  variant: "underlined"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_select, {
                  class: "ml-3",
                  items: _ctx.availableDevices,
                  placeholder: "device",
                  style: {"max-width":"110px"},
                  modelValue: _ctx.filterDevice,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterDevice) = $event)),
                  density: "compact",
                  "hide-details": "",
                  variant: "underlined"
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, {
              class: "no-gutters px-3 ma-0",
              style: {"flex-wrap":"nowrap"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_combobox, {
                  class: "ml-auto",
                  placeholder: "tags search",
                  modelValue: _ctx.filterTags,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filterTags) = $event)),
                  multiple: "",
                  "small-chips": "",
                  "hide-details": "",
                  density: "compact",
                  flat: "",
                  "deletable-chips": "",
                  variant: "underlined",
                  items: _ctx.availableTags
                }, null, 8, ["modelValue", "items"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, {
              class: "no-gutters px-2 ma-0",
              style: {"flex-wrap":"nowrap"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_menu, {
                  ref: "showCalendar",
                  modelValue: _ctx.showCalendar,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showCalendar) = $event)),
                  "close-on-content-click": false,
                  "return-value": _ctx.filterDates,
                  transition: "scale-transition",
                  "offset-y": "",
                  "min-width": "auto"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_text_field, _mergeProps({
                      modelValue: $options.filterDateString,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($options.filterDateString) = $event)),
                      "small-chips": "",
                      "prepend-icon": "mdi-calendar",
                      density: "compact",
                      "hide-details": "",
                      clearable: "",
                      variant: "underlined",
                      "onClick:clear": _cache[8] || (_cache[8] = ($event: any) => (_ctx.filterDates = [])),
                      readonly: ""
                    }, props), null, 16, ["modelValue"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_date_picker, {
                      modelValue: _ctx.filterDates,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filterDates) = $event)),
                      "allowed-dates": $options.allowedDates,
                      "show-current": "",
                      "no-title": "",
                      multiple: "",
                      scrollable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          text: "",
                          color: "primary",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showCalendar = false))
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          text: "",
                          color: "primary",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$refs.showCalendar.save(_ctx.filterDates)))
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("Ok")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "allowed-dates"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "return-value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_container, {
          class: "py-0 my-4 mr-6",
          fluid: "",
          style: {"width":"40px","flex-direction":"column"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                  ($props.enableSelect)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        icon: "",
                        style: {"color":"transparent"},
                        density: "compact",
                        small: "",
                        disabled: !_ctx.someScanSelected || _ctx.filterAnnotations,
                        onClick: $options.changeFilterSelected,
                        flat: ""
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.filterSelected)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                style: {"color":"black"}
                              }, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createTextVNode("mdi-checkbox-multiple-marked-outline")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.filterSelected)
                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createTextVNode("mdi-checkbox-multiple-marked")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.selectedScanIdList.length > 0)
                            ? (_openBlock(), _createBlock(_component_v_badge, {
                                key: 2,
                                content: _ctx.selectedScanIdList?.length
                              }, null, 8, ["content"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ], 16)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Filter to selected scans")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                  ($props.enableSelect)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        icon: "",
                        style: {"color":"transparent"},
                        density: "compact",
                        small: "",
                        disabled: _ctx.filteredAnnotationsList?.length === 0 || _ctx.filterSelected,
                        onClick: $options.changeFilterAnnotations,
                        flat: ""
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.filterAnnotations)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                style: {"color":"black"}
                              }, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode("mdi-comment-text-multiple-outline")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.filterAnnotations)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 1,
                                style: {"color":"black"}
                              }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode("mdi-comment-text-multiple")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.filteredAnnotationsList?.length > 0)
                            ? (_openBlock(), _createBlock(_component_v_badge, {
                                key: 2,
                                color: "blue",
                                content: _ctx.filteredAnnotationsList?.length
                              }, null, 8, ["content"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ], 16)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Filter to annotated scans")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                  ($props.enableSelect)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        icon: "",
                        style: {"color":"transparent"},
                        density: "compact",
                        small: "",
                        disabled: !_ctx.isFiltered || _ctx.filteredSummaryScanList?.length === 0,
                        onClick: $options.selectAll,
                        flat: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { style: {"color":"black"} }, {
                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                              _createTextVNode("mdi-playlist-plus")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ], 16)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("Select all filtered scans")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                  ($props.enableSelect)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        icon: "",
                        style: {"color":"transparent"},
                        density: "compact",
                        small: "",
                        disabled: !_ctx.someScanSelected,
                        onClick: $options.unselectAll,
                        flat: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { style: {"color":"black"} }, {
                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                              _createTextVNode("mdi-playlist-remove")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ], 16)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Unselect all scans")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              location: "bottom",
              "open-delay": 700
            }, {
              activator: _withCtx(({ props }) => [
                _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    style: {"color":"transparent"},
                    density: "compact",
                    small: "",
                    disabled: !$options.filtered,
                    onClick: $options.removeFilter,
                    flat: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { style: {"color":"black"} }, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode("mdi-filter-remove")
                        ])),
                        _: 1
                      }),
                      ($options.filtered)
                        ? (_openBlock(), _createBlock(_component_v_badge, {
                            key: 0,
                            color: "blue",
                            content: _ctx.filteredSummaryScanList?.length
                          }, null, 8, ["content"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ], 16)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Clear the filter")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}