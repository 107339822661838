import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "base-station-files"
}
const _hoisted_2 = ["colspan"]
const _hoisted_3 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_TrajectoryProcessingRunsSummary = _resolveComponent("TrajectoryProcessingRunsSummary")!
  const _component_TrajectoryProcessingRunsList = _resolveComponent("TrajectoryProcessingRunsList")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[9] || (_cache[9] = _createTextVNode("Basestation Data Files")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (!_ctx.projectId || _ctx.projectId === 0)
          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Select a project to continue...")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.projectId || _ctx.projectId > 0)
          ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  headers: $data.headers,
                  "sort-by": ['startTimestamp'],
                  "sort-desc": true,
                  items: _ctx.basestations || [],
                  "item-key": "id",
                  width: "100vw",
                  search: $data.search,
                  loading: _ctx.loading,
                  "loading-text": "Loading... Please wait",
                  "footer-props": {"items-per-page-options":[25,50,100,-1]},
                  "items-per-page": 25,
                  "show-expand": ""
                }, {
                  "item.point": _withCtx(({ item }) => [
                    _createTextVNode("lat: " + _toDisplayString(_ctx.$filters.asFloat(item.point.coordinates[1])) + ", lon: " + _toDisplayString(_ctx.$filters.asFloat(item.point.coordinates[0])), 1)
                  ]),
                  "item.s3Url": _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      onClick: ($event: any) => (_ctx.openInNewWindow(item.s3Url))
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("mdi-cloud-download-outline")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  "item.startTimestamp": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters?.utcDateTime(item.startTimestamp)), 1)
                  ]),
                  "item.endTimestamp": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters?.utcDateTime(item.endTimestamp)), 1)
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      onClick: ($event: any) => ($options.openInfoDialog(item))
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("mdi-information-outline")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      onClick: ($event: any) => (_ctx.openInNewWindow(item.s3Url))
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("mdi-cloud-download-outline")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      onClick: ($event: any) => ($options.deleteItemConfirm(item))
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode("mdi-delete")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  "item.trajectoryProcessingRuns": _withCtx(({ item }) => [
                    _cache[15] || (_cache[15] = _createElementVNode("tr", null, null, -1)),
                    (_openBlock(), _createElementBlock("td", {
                      class: "ma-0 pa-0 pl-10",
                      colspan: $data.headers?.length,
                      key: item.id
                    }, [
                      _createVNode(_component_TrajectoryProcessingRunsSummary, {
                        trajectoryProcessingRuns: item.trajectoryProcessingRuns
                      }, null, 8, ["trajectoryProcessingRuns"])
                    ], 8, _hoisted_2))
                  ]),
                  "expanded-row": _withCtx(({ item }) => [
                    _cache[16] || (_cache[16] = _createElementVNode("tr", null, null, -1)),
                    (_openBlock(), _createElementBlock("td", {
                      class: "ma-0 pa-0 pl-10",
                      colspan: $data.headers?.length,
                      key: item.id
                    }, [
                      _createVNode(_component_TrajectoryProcessingRunsList, {
                        trajectoryProcessingRuns: item.trajectoryProcessingRuns
                      }, null, 8, ["trajectoryProcessingRuns"])
                    ], 8, _hoisted_3))
                  ]),
                  top: _withCtx(() => [
                    _createVNode(_component_v_toolbar, {
                      flat: "",
                      color: "transparent"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.uploadDialog,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.uploadDialog) = $event)),
                          "max-width": "550px"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              class: "mr-2",
                              color: "primary lighten-2"
                            }, props), {
                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                _createTextVNode("mdi-plus-circle")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            ($data.uploadDialog)
                              ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                                        _createTextVNode("Upload and Process Base Station Data")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_subtitle, { class: "pt-2" }, {
                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                        _createTextVNode("Supported base station formats include Rinex verison 2 and 3. Sampling rate should be once/sec for best results. Allow 5 minutes for processing.")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_form, {
                                          modelValue: $data.isFormValid,
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.isFormValid) = $event)),
                                          ref: "createForm",
                                          disabled: $data.uploadDialogLocked
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_container, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_card, {
                                                      width: "100%",
                                                      flat: "",
                                                      onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_file_input, {
                                                          "truncate-length": "15",
                                                          "show-size": "",
                                                          accept: ".zip",
                                                          modelValue: _ctx.file,
                                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.file) = $event)),
                                                          rules: _ctx.singleBasestationFileInputRules
                                                        }, null, 8, ["modelValue", "rules"]),
                                                        (_ctx.showUpload)
                                                          ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                                                              key: 0,
                                                              max: "100",
                                                              modelValue: _ctx.fileUploadPercentage,
                                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fileUploadPercentage) = $event))
                                                            }, null, 8, ["modelValue"]))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue", "disabled"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, { "hide-details": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.closeUploadDialog,
                                          disabled: $data.uploadingFile
                                        }, {
                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                            _createTextVNode("Cancel")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"]),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.uploadFile,
                                          disabled: !$data.isFormValid || $data.uploadingFile
                                        }, {
                                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                                            _createTextVNode("Upload")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["headers", "items", "search", "loading"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: $data.infoDialog,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.infoDialog) = $event)),
      "max-width": "500px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "headline" }, {
              default: _withCtx(() => [
                _cache[22] || (_cache[22] = _createTextVNode("Overlapping Session Trajectories")),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode("\"The following session's time overlaps with basestation id " + _toDisplayString($data.basestationId) + "\"", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_data_table, {
                      headers: $data.subheaders,
                      "sort-by": ['sessionId'],
                      "sort-desc": true,
                      items: _ctx.trajectories,
                      "item-key": "sessionId",
                      width: "100vw",
                      loading: _ctx.loading,
                      "loading-text": "Loading... Please wait",
                      "hide-default-header": true,
                      "hide-default-footer": true,
                      "onClick:row": $options.handleClick
                    }, {
                      "item.sessionId": _withCtx(({ item }) => [
                        _createVNode(_component_router_link, {
                          to: { name: 'TrajectoryProcessing', params: { search: item.sessionId  }}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.sessionId), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 1
                    }, 8, ["headers", "items", "loading", "onClick:row"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => ($data.infoDialog = false))
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Ok")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: $data.deleteDialog,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.deleteDialog) = $event)),
      "max-width": "500px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "headline" }, {
              default: _withCtx(() => [
                _cache[25] || (_cache[25] = _createTextVNode("Confirm Base Station Delete")),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Are you sure you want to delete this basestation file?")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: $options.closeDeleteDialog
                }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: $options.deleteItem
                }, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createTextVNode("OK")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}