import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "publish-scan" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    ($options.hasBuckets)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          persistent: "",
          modelValue: $data.dialog,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.dialog) = $event)),
          "max-width": "550px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _cache[9] || (_cache[9] = _createTextVNode("Publish Scans")),
                    ($options.bulk)
                      ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                          default: _withCtx(() => [
                            _cache[6] || (_cache[6] = _createTextVNode("Publish ")),
                            _createElementVNode("b", null, _toDisplayString(_ctx.selectedScanIdList.length), 1),
                            _cache[7] || (_cache[7] = _createTextVNode(" scans"))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                          default: _withCtx(() => [
                            _cache[8] || (_cache[8] = _createTextVNode("Publish ")),
                            _createElementVNode("b", null, _toDisplayString(_ctx.focusedScanId), 1)
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      modelValue: $data.isFormValid,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.isFormValid) = $event)),
                      ref: "createForm"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  density: "compact",
                                  label: "Bucket",
                                  modelValue: $data.publishScans.bucketId,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.publishScans.bucketId) = $event)),
                                  items: _ctx.project.organization.buckets,
                                  "item-title": 'name',
                                  "item-value": 'id',
                                  rules: [$data.rules.required]
                                }, null, 8, ["modelValue", "items", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  prefix: `[${_ctx.project.name}] - `,
                                  modelValue: $data.publishScans.targetFolder,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.publishScans.targetFolder) = $event)),
                                  density: "compact",
                                  label: "Folder",
                                  rules: [$data.rules.required]
                                }, null, 8, ["prefix", "modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "px-5" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_checkbox, {
                                  class: "pr-3",
                                  modelValue: $data.publishScans.includeImages,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.publishScans.includeImages) = $event)),
                                  label: "Publish images"
                                }, null, 8, ["modelValue"]),
                                (_ctx.project.projection !== '')
                                  ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                      key: 0,
                                      modelValue: $data.publishScans.transform,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.publishScans.transform) = $event)),
                                      label: "Transform"
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { "hide-details": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: $options.done
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Cancel")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: $options.publish,
                      disabled: !$data.isFormValid
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Publish")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}