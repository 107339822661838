import {
  COMPLETE_PACKAGE_UPLOAD_MULTIPART,
  GET_PACKAGE_UPLOAD_MULTIPART_INFO,
} from "../graphql/package-upload-queries";

// file-multipart-upload-worker-info-model.ts
import { FileUploadWorkerInfo } from "./file-upload-worker-info-model";
import { apolloClient } from "../services/vue-apollo";

const WORKER_POOL_SIZE = 3;
const TARGET_PART_SIZE = 10 * 1024 * 1024;
const MAX_RETRIES = 2 * WORKER_POOL_SIZE + 1;
const RETRY_INTERVAL = 5000;

class PartWorkerInfo {
  worker: Worker;
  isFree: boolean;
  currentPartNumber: number;
  progress: number;
  onPartProgress?: (progress: number) => void;
  onPartComplete?: (eTag: string) => void;
  onPartError?: (error: any) => void;

  constructor() {
    this.worker = new Worker("worker-file-multipart-upload.js");
    this.isFree = true;
    this.currentPartNumber = 0;
    this.progress = 0;

    this.worker.onmessage = function (e) {
      console.log(
        "Raw progress from multipart worker:",
        e.data.progress,
        "for part:",
        this.currentPartNumber
      );
      switch (e.data.type) {
        case "progress":
          if (typeof e.data.progress === "number") {
            this.progress = e.data.progress;
            if (this.onPartProgress) {
              this.onPartProgress(e.data.progress);
            }
          }
          break;
        case "complete":
          this.progress = 100;
          if (this.onPartComplete) {
            this.onPartComplete(e.data.etag);
          }
          break;
        case "error":
          if (this.onPartError) {
            this.onPartError(e.data);
          }
          break;
      }
    }.bind(this);

    this.worker.onerror = function (e) {
      if (this.onPartError) {
        this.onPartError(e);
      }
    }.bind(this);
  }

  assignToPart(partNumber: number) {
    this.isFree = false;
    this.currentPartNumber = partNumber;
    this.progress = 0;
  }

  freeWorker() {
    this.isFree = true;
    this.currentPartNumber = 0;
    this.progress = 0;
  }
}

export class FileMultipartUploadWorkerInfo extends FileUploadWorkerInfo {
  errorCount: number;
  retryTimeouts: Array<ReturnType<typeof setTimeout>>;
  uploadId: string;
  numberOfParts: number;
  nextPartNumber: number;
  partInfoList: { eTag?: string; partNumber: number; signedUrl: string }[];
  partWorkerPool: PartWorkerInfo[];

  constructor(file: File, url?: string) {
    super(file, url);
    this.numberOfParts = Math.ceil(this.file.size / TARGET_PART_SIZE);
    this.nextPartNumber = 1;
    this.partInfoList = Array.from({ length: this.numberOfParts }, (_, i) => ({
      partNumber: i + 1,
      signedUrl: "",
      eTag: undefined,
    }));
    this.partWorkerPool = new Array<PartWorkerInfo>(WORKER_POOL_SIZE);
    for (let i = 0; i < WORKER_POOL_SIZE; i++) {
      const workerInfo = new PartWorkerInfo();
      this.partWorkerPool[i] = workerInfo;
      workerInfo.onPartProgress = () => this.calculateOverallProgress();
      workerInfo.onPartComplete = (eTag) => {
        const partNumber = workerInfo.currentPartNumber;
        this.partInfoList[partNumber - 1].eTag = eTag;
        workerInfo.freeWorker();
        this.calculateOverallProgress();
        this.startNextPartUpload();
      };
      workerInfo.onPartError = (e) => {
        this.handleError(e, () => {
          this.startPartUploadOnWorker(
            workerInfo.currentPartNumber,
            workerInfo
          );
        });
      };
    }
  }

  completeMultipartUpload(eTags: string[]) {
    this.progressMonitor.setComplete();
    this.state.setComplete();

    apolloClient
      .mutate({
        mutation: COMPLETE_PACKAGE_UPLOAD_MULTIPART,
        variables: {
          filename: this.name,
          uploadId: this.uploadId,
          eTags: eTags.join(","),
        },
      })
      .then(() => {
        if (this.onComplete) this.onComplete();
      })
      .catch((ex) => {
        if (this.onError) this.onError(ex);
      });
  }

  calculateOverallProgress() {
    if (this.state.isComplete) return;

    const fullyCompleteParts = this.partInfoList.filter((p) => p.eTag).length;
    if (fullyCompleteParts === this.numberOfParts) {
      this.completeMultipartUpload(this.partInfoList.map((p) => p.eTag!));
      return;
    }

    const maxProgressPerPart = 100 / this.numberOfParts;
    let overallProgress = fullyCompleteParts * maxProgressPerPart;
    for (const worker of this.partWorkerPool) {
      if (!worker.isFree) {
        overallProgress += (worker.progress / 100) * maxProgressPerPart;
        console.log(
          `Part ${worker.currentPartNumber} progress: ${
            worker.progress
          }% contributes ${(worker.progress / 100) * maxProgressPerPart}%`
        );
      }
    }
    console.log("Calculated overall progress:", overallProgress);
    this.progressMonitor.setProgress(overallProgress);
    console.log(
      "Progress monitor state:",
      this.progressMonitor.progress,
      "uploadedBytes:",
      this.progressMonitor.uploadedBytes
    );
    if (this.progressMonitor.progress < 100 && this.onProgress) {
      this.onProgress(
        this.progressMonitor.progress,
        this.progressMonitor.uploadedBytes
      );
    }
  }

  getRetryTimeoutTime() {
    return RETRY_INTERVAL;
  }

  handleError(error: any, retryFn?: () => void) {
    this.errorCount++;
    if (retryFn && this.errorCount <= MAX_RETRIES) {
      const timeoutTime = this.getRetryTimeoutTime();
      const timeout = setTimeout(() => retryFn(), timeoutTime);
      this.retryTimeouts.push(timeout);
      return;
    }
    this.retryTimeouts.forEach((t) => clearTimeout(t));
    this.progressMonitor.stopProgressMonitoring();
    this.state.setError(error);
    this.onError?.(error);
  }

  getNextFreePartWorker(partNumber: number): PartWorkerInfo | undefined {
    for (const worker of this.partWorkerPool) {
      if (worker.isFree) {
        worker.assignToPart(partNumber);
        return worker;
      }
    }
    return undefined;
  }

  startPartUploadOnWorker(partNumber: number, workerInfo: PartWorkerInfo) {
    const offset = (partNumber - 1) * TARGET_PART_SIZE;
    const part = this.file.slice(offset, offset + TARGET_PART_SIZE);
    workerInfo.worker.postMessage([
      part,
      this.file.type,
      this.file.name,
      this.partInfoList[partNumber - 1].signedUrl,
    ]);
  }

  startNextPartUpload() {
    if (this.state.hasError) return;

    while (
      this.nextPartNumber <= this.numberOfParts &&
      this.partInfoList[this.nextPartNumber - 1]?.eTag
    ) {
      this.nextPartNumber++;
    }
    if (this.nextPartNumber > this.numberOfParts) return;

    const workerInfo = this.getNextFreePartWorker(this.nextPartNumber);
    if (workerInfo) {
      this.startPartUploadOnWorker(this.nextPartNumber, workerInfo);
      this.nextPartNumber++;
    }
  }

  startMultipartUpload(info: {
    filename: string;
    uploadId: string;
    parts: { eTag: string; partNumber: number; signedUrl: string }[];
  }) {
    this.url = info.filename;
    this.uploadId = info.uploadId;
    this.partInfoList = info.parts;

    for (let i = 0; i < Math.min(this.numberOfParts, WORKER_POOL_SIZE); i++) {
      this.startNextPartUpload();
    }

    this.state.setInProgress();
    this.progressMonitor.startProgressMonitoring();
    this.startTime = new Date();
    this.onStarted?.(`Started uploading ${this.file.name}...`);
    this.calculateOverallProgress();
  }

  startUpload() {
    apolloClient
      .query({
        query: GET_PACKAGE_UPLOAD_MULTIPART_INFO,
        variables: {
          filename: this.name,
          numberOfParts: this.numberOfParts,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) =>
        this.startMultipartUpload(res.data.getPackageUploadMultipartInfo)
      )
      .catch((ex) => this.handleError(ex, () => this.startUpload()));
  }
}
