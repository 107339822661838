// src/main.ts

import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/style.css";
import "vue-datetime/dist/vue-datetime.css";

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client/core";
import { apolloProvider, filesRoot } from "./services/vue-apollo";

import App from "./app.vue";
import Datepicker from "vue3-datepicker";
// import IdleVue from "idle-vue";
import JsonEditorVue from "json-editor-vue";
import { createApolloProvider } from "@vue/apollo-option";
import { createApp } from "vue";
import filters from "./services/filters";
import { initializeAuth } from "./services/auth"; // Import the initialization function for auth
import router from "./router";
import { setGlobalConfig } from "./config/config.js";
import snackbarPlugin from "./plugins/snackbar";
import store from "./store/store";
import vuetify from "@/plugins/vuetify";

let config: any = null;

const app = createApp(App);

export const loadConfig = async () => {
  try {
    const response = await fetch("/config/config.json");
    if (!response.ok) {
      throw new Error(`Get config error: ${response.status}`);
    }
    config = await response.json();
    setGlobalConfig(config);
  } catch (error) {
    console.error("Failed to load config:", error);
    throw error;
  }
};

const initializeApp = async () => {
  try {
    await loadConfig();
    initializeAuth();
  } catch (error) {
    console.error("Failed to initialize the app:", error);
    const loading = document.getElementById("loading");
    if (loading) {
      loading.innerHTML =
        "<h2>Failed to load configuration. Please try again later.</h2>";
    }
    return;
  }

  // const eventsHub = new Vue();

  // // Install Vue plugins
  // app.use(IdleVue, {
  //   eventEmitter: eventsHub,
  //   idleTime: 1000 * 60 * 15, // 15 minutes
  //   store: store,
  //   startAtIdle: false,
  // });

  const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
  });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  app.config.globalProperties.$filesRoot = filesRoot;

  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
  });

  app.config.globalProperties.$filters = filters;

  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.use(apolloProvider);
  app.use(JsonEditorVue);
  app.use(snackbarPlugin, { store });

  app.component("datetime-selector", Datepicker);

  app.mount("#app");

  const loading = document.getElementById("loading");
  if (loading) {
    loading.style.display = "none";
  }
};

initializeApp();
