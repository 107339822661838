import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "map-container",
  ref: "mapContainer"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "pa-0 ma-0 fill-height flex-column",
    id: "mapView",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, null, 512),
      _createVNode(_component_v_container, {
        class: "mt-1 ml-5 pr-5",
        id: "mapPanel",
        fluid: "",
        style: {"position":"absolute","z-index":"1","background-color":"rgba(0,0,0,0)","color":"white","width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "d-flex" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "bottom",
                    "open-delay": 700
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        small: "",
                        color: "transparent"
                      }, props, {
                        onClick: _ctx.setZoomFocusToAll,
                        flat: ""
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("mdi-focus-field")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("Zoom to view all scans")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_tooltip, {
                    location: "bottom",
                    "open-delay": 700
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        small: "",
                        color: "transparent"
                      }, props, {
                        onClick: _ctx.setZoomFocus,
                        flat: ""
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("mdi-image-filter-center-focus")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("Zoom to view all selected scans")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "3" }),
              _createVNode(_component_v_col, {
                class: "flex",
                cols: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, { "offset-y": "" }, {
                    activator: _withCtx(({ props }) => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_chip, _mergeProps({ color: "white" }, props, { style: {"background":"#363636"} }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_avatar, { class: "mr-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { color: "white" }, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("mdi-palette-outline")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createElementVNode("span", null, _toDisplayString(_ctx.selectedMapStyle), 1)
                        ]),
                        _: 2
                      }, 1040)), [
                        [_directive_ripple]
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mapStyleNames, (item) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: item,
                              onClick: ($event: any) => (_ctx.selectStyle(item))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "2" }),
              _createVNode(_component_v_col, {
                class: "d-flex",
                cols: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "bottom",
                    "open-delay": 700
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "pt-1",
                        icon: "",
                        density: "compact",
                        small: "",
                        color: "transparent"
                      }, props, {
                        onClick: _ctx.measuringTool,
                        style: {"text-transform":"none"},
                        flat: ""
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "pr-2" }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("mdi-ruler")
                            ])),
                            _: 1
                          }),
                          (_ctx.measuring)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Total Distance: " + _toDisplayString(_ctx.measuringDistance.toLocaleString()) + " km", 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1040, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Measuring tool")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "1" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}