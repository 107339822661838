import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "headline" }
const _hoisted_2 = {
  class: "ma-0 pa-0",
  id: "calendar"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_calendar = _resolveComponent("v-calendar")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_card, {
    width: "100%",
    height: "100%",
    style: {"display":"flex","flex-direction":"column","height":"100%","max-height":"100%","box-sizing":"border-box"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
        default: _withCtx(() => [
          _cache[30] || (_cache[30] = _createTextVNode("Device Assignment")),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_dialog, {
            persistent: "",
            modelValue: $data.editingDialog,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.editingDialog) = $event)),
            "max-width": "650px"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_icon, _mergeProps({
                class: "mr-2",
                color: "primary lighten-2"
              }, props), {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("mdi-plus-circle")
                ])),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_card, { style: {"overflow":"visible"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, _toDisplayString($options.formTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, {
                        modelValue: $data.isFormValid,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.isFormValid) = $event)),
                        ref: "createForm"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        density: "compact",
                                        label: "Project",
                                        modelValue: $data.editedItem.project,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.editedItem.project) = $event)),
                                        items: $options.projects,
                                        "item-title": "displayName",
                                        "item-value": "id",
                                        "return-object": "",
                                        rules: [$data.rules.required]
                                      }, null, 8, ["modelValue", "items", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_combobox, {
                                        placeholder: "Selected Devices",
                                        modelValue: $data.editedItem.device,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.editedItem.device) = $event)),
                                        items: $options.devices,
                                        "small-chips": "",
                                        clearable: "",
                                        "hide-details": "",
                                        density: "compact",
                                        flat: "",
                                        "deletable-chips": "",
                                        width: "250px"
                                      }, {
                                        selection: _withCtx(({ item, index }) => [
                                          _createVNode(_component_v_chip, {
                                            "text-color": "white",
                                            color: $data.colors[$options.getColorIndex(item.value)],
                                            small: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.value), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["color"])
                                        ]),
                                        item: _withCtx(({ active, item, props }) => [
                                          _createVNode(_component_v_list_item, { style: {"display":"flex","flex-direction":"row","white-space":"nowrap","flex-wrap":"nowrap"} }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_action, _normalizeProps(_guardReactiveProps(props)), {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_checkbox_btn, { "input-value": active }, null, 8, ["input-value"]),
                                                  _createVNode(_component_v_icon, {
                                                    class: "pr-2",
                                                    "text-color": "white",
                                                    color: $data.colors[$options.getColorIndex(item.value)]
                                                  }, {
                                                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                      _createTextVNode("mdi-square")
                                                    ])),
                                                    _: 2
                                                  }, 1032, ["color"]),
                                                  _createTextVNode(_toDisplayString(item.value), 1)
                                                ]),
                                                _: 2
                                              }, 1040)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        density: "compact",
                                        label: "Assignment Type",
                                        modelValue: $data.editedItem.assignmentType,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.editedItem.assignmentType) = $event)),
                                        items: $data.assignmentTypes,
                                        rules: [$data.rules.required]
                                      }, null, 8, ["modelValue", "items", "rules"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _cache[25] || (_cache[25] = _createTextVNode("From (UTC)")),
                                      _createVNode(_component_VueDatePicker, {
                                        modelValue: $data.editedItem.from,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.editedItem.from) = $event)),
                                        clearable: true,
                                        style: {"border-bottom":"1px solid grey","background":"#F5F5F5"}
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _cache[26] || (_cache[26] = _createTextVNode("To (UTC)")),
                                      _createVNode(_component_VueDatePicker, {
                                        modelValue: $data.editedItem.to,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.editedItem.to) = $event)),
                                        clearable: true,
                                        style: {"border-bottom":"1px solid grey","background":"#F5F5F5"}
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                color: "blue darken-1",
                                text: "",
                                onClick: $options.closeDialog
                              }, {
                                default: _withCtx(() => _cache[27] || (_cache[27] = [
                                  _createTextVNode("Cancel")
                                ])),
                                _: 1
                              }, 8, ["onClick"]),
                              ($data.editedIndex !== -1)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    color: "blue darken-1",
                                    text: "",
                                    onClick: $options.deleteItem
                                  }, {
                                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                                      _createTextVNode("Delete")
                                    ])),
                                    _: 1
                                  }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_btn, {
                                color: "blue darken-1",
                                text: "",
                                onClick: $options.saveItem,
                                disabled: !$options.isFormValidWithDates
                              }, {
                                default: _withCtx(() => _cache[29] || (_cache[29] = [
                                  _createTextVNode("Save")
                                ])),
                                _: 1
                              }, 8, ["onClick", "disabled"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_toolbar, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "ma-2",
              icon: "",
              onClick: $options.prevCalendar
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { large: "" }, {
                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                    _createTextVNode("mdi-chevron-left")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_btn, {
              class: "mr-4",
              outlined: "",
              color: "grey darken-2",
              onClick: $options.setToday
            }, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode("Today")
              ])),
              _: 1
            }, 8, ["onClick"]),
            ($options.cal)
              ? (_openBlock(), _createBlock(_component_v_toolbar_title, {
                  key: 0,
                  class: "pr-5"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("U " + _toDisplayString($options.cal.title), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_menu, {
              bottom: "",
              right: ""
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  outlined: "",
                  color: "grey darken-2"
                }, props), {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString($data.typeToLabel[$data.type]), 1),
                    _createVNode(_component_v_icon, { right: "" }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("mdi-menu-down")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[7] || (_cache[7] = ($event: any) => ($data.type = 'day'))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode("Day")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => ($data.type = 'week'))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[35] || (_cache[35] = [
                            _createTextVNode("Week")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[9] || (_cache[9] = ($event: any) => ($data.type = 'month'))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[36] || (_cache[36] = [
                            _createTextVNode("Month")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list_item, {
                      onClick: _cache[10] || (_cache[10] = ($event: any) => ($data.type = '4day'))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => _cache[37] || (_cache[37] = [
                            _createTextVNode("4 Days")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_checkbox, {
              class: "mr-3",
              label: "Project Sessions",
              density: "compact",
              "hide-details": "",
              modelValue: $data.showAssigned,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.showAssigned) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_v_combobox, {
              class: "mr-3",
              placeholder: "Selected Devices",
              modelValue: $data.selectedDevices,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($data.selectedDevices) = $event)),
              items: $options.devices,
              multiple: "",
              "small-chips": "",
              clearable: "",
              "hide-details": "",
              density: "compact",
              flat: "",
              "deletable-chips": "",
              width: "250px"
            }, {
              selection: _withCtx(({ item, index }) => [
                _createVNode(_component_v_chip, {
                  "text-color": "white",
                  color: $data.colors[$options.getColorIndex(item.value)],
                  small: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.value), 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              item: _withCtx(({ active, item, props }) => [
                _createVNode(_component_v_list_item, {
                  style: {"display":"flex","flex-direction":"row","white-space":"nowrap","flex-wrap":"nowrap"},
                  onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_action, _normalizeProps(_guardReactiveProps(props)), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          modelValue: $data.selectedDevices,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($data.selectedDevices) = $event)),
                          value: item,
                          "hide-details": "",
                          density: "compact",
                          onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"]))
                        }, null, 8, ["modelValue", "value"]),
                        _createVNode(_component_v_icon, {
                          class: "pr-2",
                          "text-color": "white",
                          color: $data.colors[$options.getColorIndex(item.value)]
                        }, {
                          default: _withCtx(() => _cache[38] || (_cache[38] = [
                            _createTextVNode("mdi-square")
                          ])),
                          _: 2
                        }, 1032, ["color"]),
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["modelValue", "items"]),
            _createVNode(_component_v_combobox, {
              placeholder: "Selected Projects",
              modelValue: $data.selectedProjects,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($data.selectedProjects) = $event)),
              items: $options.projects,
              multiple: "",
              "small-chips": "",
              clearable: "",
              "hide-details": "",
              density: "compact",
              flat: "",
              "deletable-chips": "",
              width: "250px",
              onMousedown: _cache[21] || (_cache[21] = _withModifiers(() => {}, ["prevent","stop"]))
            }, {
              selection: _withCtx(({ item, index }) => [
                _createVNode(_component_v_chip, {
                  onClick: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["stop"])),
                  "text-color": "white",
                  color: $data.colors[$options.getColorIndex(item.id)],
                  small: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item?.value?.displayName), 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              item: _withCtx(({ active, item, props }) => [
                (_openBlock(), _createBlock(_component_v_list_item, {
                  style: {"display":"flex","flex-direction":"row","justify-content":"flex-start","align-items":"flex-start"},
                  onClick: _cache[19] || (_cache[19] = _withModifiers(() => {}, ["stop"])),
                  key: item.value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_action, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, _mergeProps({
                          modelValue: $data.selectedProjects,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($data.selectedProjects) = $event)),
                          value: item,
                          "hide-details": ""
                        }, props, {
                          onClick: _cache[18] || (_cache[18] = _withModifiers(() => {}, ["prevent","stop"])),
                          density: "compact"
                        }), null, 16, ["modelValue", "value"]),
                        _createVNode(_component_v_icon, {
                          class: "pr-2",
                          "text-color": "white",
                          color: $data.colors[$options.getColorIndex(item.raw.id)]
                        }, {
                          default: _withCtx(() => _cache[39] || (_cache[39] = [
                            _createTextVNode("mdi-square")
                          ])),
                          _: 2
                        }, 1032, ["color"]),
                        _createTextVNode(_toDisplayString($options.getProjectName(item)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              ]),
              _: 1
            }, 8, ["modelValue", "items"]),
            _createVNode(_component_v_btn, {
              class: "ma-2",
              icon: "",
              onClick: $options.nextCalendar
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { large: "" }, {
                  default: _withCtx(() => _cache[40] || (_cache[40] = [
                    _createTextVNode("mdi-chevron-right")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_sheet, {
          class: "flex-grow-1",
          style: {"min-height":"0"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_calendar, {
              ref: "calendar",
              categories: "Assignment",
              style: {"display":"flex","flex-direction":"column","flex":"1","min-height":"0"},
              modelValue: $data.focus,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($data.focus) = $event)),
              weekdays: $data.weekday,
              "view-mode": $data.type,
              events: $data.events,
              "event-overlap-mode": $data.mode,
              "event-overlap-threshold": 30,
              "event-color": $options.getEventColor,
              onChange: $options.getEvents,
              "onClick:event": $options.editEvent,
              "onClick:more": $options.viewDay,
              "onClick:date": $options.viewDay,
              "onClick:time": $options.createEvent,
              "onClick:day": $options.createEvent
            }, {
              "day-body": _withCtx(({ date, week }) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["v-current-time", { first: date === week[0].date }]),
                  style: _normalizeStyle({ top: $options.nowY })
                }, null, 6)
              ]),
              event: _withCtx(({ event }) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer","pointer-events":"auto","font-size":"14px","padding":"2px","overflow":"hidden","background":"rgba(200,200,200,1)","border-radius":"10px","display":"flex","flex-direction":"row"},
                  onClick: _withModifiers(($event: any) => ($options.editEvent(event)), ["stop"])
                }, [
                  _createVNode(_component_v_icon, {
                    color: event.color
                  }, {
                    default: _withCtx(() => _cache[41] || (_cache[41] = [
                      _createTextVNode("mdi-square")
                    ])),
                    _: 2
                  }, 1032, ["color"]),
                  _createTextVNode(_toDisplayString(event.title), 1)
                ], 8, _hoisted_3)
              ]),
              _: 1
            }, 8, ["modelValue", "weekdays", "view-mode", "events", "event-overlap-mode", "event-color", "onChange", "onClick:event", "onClick:more", "onClick:date", "onClick:time", "onClick:day"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}