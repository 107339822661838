import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 d-flex",
  id: "admin-orgs"
}
const _hoisted_2 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createTextVNode(" Organizations")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_data_table, {
          headers: $data.headers,
          "show-group-by": "",
          items: _ctx.organizations || [],
          "item-key": "id",
          search: $data.search,
          loading: $options.loading,
          width: "800px",
          "loading-text": "Loading... Please wait",
          "footer-props": {"items-per-page-options":[25,50,100,-1]},
          "items-per-page": 25
        }, {
          "item.devices": _withCtx(({ item }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.devices, (device) => {
              return (_openBlock(), _createBlock(_component_v_chip, {
                flatdensity: "compact",
                "small-chips": "",
                key: device.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(device.id), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, {
              flat: "",
              color: "transparent"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialog,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.dialog) = $event)),
                  "max-width": "550px"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_icon, _mergeProps({
                      class: "mr-2",
                      color: "primary lighten-2"
                    }, props), {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("mdi-plus-circle")
                      ])),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    ($data.dialog)
                      ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_2, _toDisplayString($options.formTitle), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_form, {
                                  modelValue: $data.isFormValid,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.isFormValid) = $event)),
                                  ref: "createForm"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_container, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "6" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.name,
                                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.editedItem.name) = $event)),
                                                  density: "compact",
                                                  label: "Name",
                                                  rules: [$data.rules.required]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_combobox, {
                                                  class: "ml-auto",
                                                  placeholder: "Assigned Devices",
                                                  modelValue: $data.editedItem.devices,
                                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.editedItem.devices) = $event)),
                                                  "item-title": "id",
                                                  "item-value": "id",
                                                  multiple: "",
                                                  "small-chips": "",
                                                  "hide-details": "",
                                                  density: "compact",
                                                  flat: "",
                                                  "deletable-chips": "",
                                                  items: _ctx.devices
                                                }, null, 8, ["modelValue", "items"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.close
                                        }, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("Cancel")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick"]),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.save,
                                          disabled: !$data.isFormValid
                                        }, {
                                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                                            _createTextVNode("Save")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialogDelete,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.dialogDelete) = $event)),
                  "max-width": "500px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "headline" }, {
                          default: _withCtx(() => [
                            _cache[10] || (_cache[10] = _createTextVNode("Confirm User Deletion")),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Are you sure you want to delete user '" + _toDisplayString($data.editedItem.name) + "'?", 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              color: "blue darken-1",
                              text: "",
                              onClick: $options.closeDelete
                            }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("Cancel")
                              ])),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              color: "blue darken-1",
                              text: "",
                              onClick: $options.deleteItemConfirm
                            }, {
                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                _createTextVNode("OK")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          "item.actions": _withCtx(({ item }) => [
            _createVNode(_component_v_icon, {
              class: "mr-2",
              small: "",
              onClick: ($event: any) => ($options.editItem(item))
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("mdi-pencil")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_v_icon, {
              small: "",
              onClick: ($event: any) => ($options.deleteItem(item))
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("mdi-delete")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }, 8, ["headers", "items", "search", "loading"])
      ]),
      _: 1
    })
  ]))
}