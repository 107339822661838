import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createBlock(_component_v_overlay, {
    value: _ctx.loggedIn && !_ctx.hasQueuedOrActiveUploads,
    opacity: 0.8,
    "z-index": 1000
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, "You will be logged out due to inactivity in " + _toDisplayString(_ctx.inactivityCountdown) + " seconds...", 1)
    ]),
    _: 1
  }, 8, ["value"]))
}