import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "admin-trajectory"
}
const _hoisted_2 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_TrajectoryProcessingRunsList = _resolveComponent("TrajectoryProcessingRunsList")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[3] || (_cache[3] = _createTextVNode("Trajectory Processing")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (!_ctx.projectId || _ctx.projectId === 0)
          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Select a project to continue...")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.projectId || _ctx.projectId > 0)
          ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  headers: $data.headers,
                  "sort-by": ['capturedTimestamp'],
                  "sort-desc": true,
                  items: _ctx.sessionSummary || [],
                  search: $data.search,
                  "item-key": "sessionId",
                  width: "100vw",
                  loading: _ctx.loading,
                  "loading-text": "Loading... Please wait",
                  "footer-props": { 'items-per-page-options': [25, 50, 100, -1] },
                  "items-per-page": 25,
                  "show-expand": ""
                }, {
                  "item.capturedTimestamp": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.capturedTimestamp)), 1)
                  ]),
                  "item.endCapturedTimestamp": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.endCapturedTimestamp)), 1)
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      onClick: ($event: any) => ($options.openInfoDialog(item))
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("mdi-information-outline")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  "item.status": _withCtx(({ item }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getFinalStatuses(item), (status, index) => {
                      return (_openBlock(), _createBlock(_component_v_chip, {
                        class: "ma-2",
                        key: `final-${index}`,
                        color: "green",
                        small: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(status), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getProvisionalStatuses(item), (status, index) => {
                      return (_openBlock(), _createBlock(_component_v_chip, {
                        class: "ma-2",
                        key: `provisional-${index}`,
                        color: "yellow",
                        small: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(status), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  "expanded-row": _withCtx(({ item }) => [
                    _cache[6] || (_cache[6] = _createElementVNode("tr", { class: "ma-0 pa-0" }, null, -1)),
                    _createElementVNode("td", {
                      class: "ma-0 pa-0",
                      colspan: $data.headers.length
                    }, [
                      _createVNode(_component_TrajectoryProcessingRunsList, {
                        trajectoryProcessingRuns: item.trajectoryProcessingRuns
                      }, null, 8, ["trajectoryProcessingRuns"])
                    ], 8, _hoisted_2)
                  ]),
                  _: 1
                }, 8, ["headers", "items", "search", "loading"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: $data.infoDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.infoDialog) = $event)),
      "max-width": "1100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "headline" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Recommended Basestation Files for Processing")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _cache[8] || (_cache[8] = _createTextVNode(" The following basestation files are potential matches for ")),
                _createElementVNode("b", null, _toDisplayString($data.selectedSession), 1),
                _cache[9] || (_cache[9] = _createTextVNode(". Recommended indicates which were used when the basestation file was uploaded."))
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "ma-0 pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  "show-select": "",
                  modelValue: $data.selected,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.selected) = $event)),
                  headers: $data.subheaders,
                  items: _ctx.potentialBasestations || [],
                  "item-value": "basestation.id",
                  width: "100vw",
                  loading: _ctx.loading,
                  "loading-text": "Loading... Please wait",
                  "hide-default-footer": true
                }, {
                  "item.session.startPoint": _withCtx(({ item }) => [
                    _createTextVNode("(" + _toDisplayString(_ctx.$filters.asFloat(item.session.startPoint.coordinates[1])) + ", " + _toDisplayString(_ctx.$filters.asFloat(item.session.startPoint.coordinates[0])) + ") ", 1),
                    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode("(" + _toDisplayString(_ctx.$filters.asFloat(item.session.endPoint.coordinates[1])) + ", " + _toDisplayString(_ctx.$filters.asFloat(item.session.endPoint.coordinates[0])) + ")", 1)
                  ]),
                  "item.basestation.point": _withCtx(({ item }) => [
                    _createTextVNode("(" + _toDisplayString(_ctx.$filters.asFloat(item.basestation.point.coordinates[1])) + ", " + _toDisplayString(_ctx.$filters.asFloat(item.basestation.point.coordinates[0])) + ")", 1)
                  ]),
                  "item.selected": _withCtx(({ item }) => [
                    ($options.basestationSelected(item.basestation.id))
                      ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("mdi-check")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  "item.distance": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.asFloat(item.distance)) + "kms", 1)
                  ]),
                  "item.basestation.id": _withCtx(({ item }) => [
                    _createVNode(_component_router_link, {
                      to: { name: 'Basestation', params: { search: item.basestation.id } }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.basestation.id), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  "item.basestation.startTimestamp": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.basestation.startTimestamp)) + " ", 1),
                    _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.basestation.endTimestamp)), 1)
                  ]),
                  "item.session.capturedTimestamp.timestamp": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.session.capturedTimestamp)) + " ", 1),
                    _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.session.endCapturedTimestamp)), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "headers", "items", "loading"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  disabled: !($data.selected && $data.selected.length > 0),
                  onClick: $options.submit
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Submit")
                  ])),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: $options.closeInfoDialog
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("Close")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}