import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "pdal-submission" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_JsonEditorVue = _resolveComponent("JsonEditorVue")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialog) = $event)),
      "max-width": "750px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "headline" }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createTextVNode("Post Processing")),
                (!$options.bulk)
                  ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                      default: _withCtx(() => [
                        _cache[6] || (_cache[6] = _createTextVNode("Submit ")),
                        _createElementVNode("b", null, _toDisplayString(_ctx.focusedScanId) + ", " + _toDisplayString(_ctx.focusedVersion), 1),
                        _cache[7] || (_cache[7] = _createTextVNode(" for post processing. "))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                      default: _withCtx(() => [
                        _cache[8] || (_cache[8] = _createTextVNode("Submit ")),
                        _createElementVNode("b", null, _toDisplayString(_ctx.selectedScanIdList.length), 1),
                        _cache[9] || (_cache[9] = _createTextVNode(" for post processing."))
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  ref: "createForm",
                  modelValue: _ctx.isFormValid,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isFormValid) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "5" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  density: "compact",
                                  label: "Pipeline",
                                  modelValue: _ctx.selectedPipeline,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPipeline) = $event)),
                                  items: $options.pipelinesList,
                                  "item-title": 'name',
                                  "return-object": "",
                                  rules: [_ctx.rules.required]
                                }, null, 8, ["modelValue", "items", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "4" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.versionName,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.versionName) = $event)),
                                  density: "compact",
                                  label: "Version Name",
                                  rules: [_ctx.rules.required]
                                }, null, 8, ["modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              class: "align-right",
                              cols: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  disabled: !$options.advancedOk,
                                  label: "Advanced",
                                  density: "compact",
                                  modelValue: _ctx.advanced,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.advanced) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                (_ctx.advanced)
                                  ? (_openBlock(), _createBlock(_component_JsonEditorVue, {
                                      key: 0,
                                      modelValue: _ctx.pipelinejson,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pipelinejson) = $event)),
                                      mode: "Mode.text",
                                      stringified: false,
                                      height: "300px"
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: $options.done
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: $options.processPDAL,
                  disabled: !_ctx.isFormValid
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("OK")
                  ])),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}