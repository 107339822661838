import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { VCalendar } from "vuetify/labs/VCalendar";
import { createVuetify } from "vuetify";

export default createVuetify({
  components: {
    ...components,
    VCalendar,
  },
  directives,
});
