import moment from "moment";

export default {
  friendlyTime(value: any) {
    return value ? moment(value).local().fromNow() : "";
  },
  absoluteTime(value: any) {
    return value ? moment(value).local().format("LLLL") : "";
  },
  localDateTime(value: any) {
    return value ? moment(value).local().format("YYYY-MM-DD hh:mm") : "";
  },
  utcDateTime(value: any) {
    return value ? value.toString().replace("T", " ").substring(0, 16) : "";
  },
  asFloat(value: any) {
    return value ? value.toFixed(2) : NaN;
  },
};
