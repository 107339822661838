import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "admin-pipeline-management"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_JsonEditorVue = _resolveComponent("JsonEditorVue")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[13] || (_cache[13] = _createTextVNode("Pipeline Management")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              density: "compact",
              "hide-details": "",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (!_ctx.projectId || _ctx.projectId === 0)
          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Select a project to continue...")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.projectId || _ctx.projectId > 0)
          ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  headers: $data.headers,
                  "show-group-by": "",
                  items: _ctx.pipelines || [],
                  "item-key": "id",
                  search: $data.search,
                  width: "800px",
                  "loading-text": "Loading... Please wait",
                  "footer-props": { 'items-per-page-options': [25, 50, 100, -1] },
                  "items-per-page": 25
                }, {
                  "item.dependencies": _withCtx(({ item }) => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: item.dependencies,
                      "onUpdate:modelValue": ($event: any) => ((item.dependencies) = $event),
                      disabled: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  "item.lastModified": _withCtx(({ item }) => [
                    _cache[15] || (_cache[15] = _createElementVNode("template", null, null, -1)),
                    _createTextVNode(_toDisplayString(new Date(item.lastModified).toLocaleString()), 1)
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      class: "mr-2",
                      small: "",
                      onClick: ($event: any) => ($options.openEdit(item))
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode("mdi-pencil")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_v_icon, {
                      small: "",
                      onClick: ($event: any) => ($options.deletePipeline(item))
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("mdi-delete")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  top: _withCtx(() => [
                    _createVNode(_component_v_toolbar, {
                      flat: "",
                      color: "transparent"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.newDialog,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.newDialog) = $event)),
                          "max-width": "850px"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              class: "mr-2",
                              color: "primary lighten-2"
                            }, props), {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createTextVNode("mdi-plus-circle")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            ($data.newDialog)
                              ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                        _createElementVNode("span", { class: "headline" }, "Create Pipeline", -1)
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_form, { ref: "createForm" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_container, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $data.newName,
                                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.newName) = $event)),
                                                          density: "compact",
                                                          label: "Pipeline Name",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_combobox, {
                                                          class: "mt-1",
                                                          modelValue: $data.newTags,
                                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.newTags) = $event)),
                                                          placeholder: "Click to Add Pipeline Dependencies",
                                                          multiple: "",
                                                          "small-chips": "",
                                                          "hide-details": "",
                                                          density: "compact",
                                                          flat: "",
                                                          "deletable-chips": "",
                                                          items: $options.tagOptions
                                                        }, null, 8, ["modelValue", "items"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { class: "pb-0 mb-0" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "9" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          class: "mt-1",
                                                          label: "Click to Add Description",
                                                          modelValue: $data.newDescription,
                                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.newDescription) = $event)),
                                                          density: "compact",
                                                          flat: "",
                                                          maxlength: "200",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, { cols: "3" }, {
                                                      default: _withCtx(() => [
                                                        ($options.globalPermission)
                                                          ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                                              key: 0,
                                                              label: "Global Pipeline",
                                                              density: "compact",
                                                              modelValue: $data.newGlobal,
                                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.newGlobal) = $event))
                                                            }, null, 8, ["modelValue"]))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { class: "no-gutters" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        ($options.hasBoundingBox)
                                                          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
                                                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                                _createTextVNode("Supports the following dependencies: [<%xmin%>,<%xmax%>],[<%ymin%>,<%ymax%>], [<%zmin%>,<%zmax%>]")
                                                              ])),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { class: "pt-0 mt-0" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_JsonEditorVue, {
                                                          modelValue: $data.newPipeline,
                                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.newPipeline) = $event)),
                                                          mode: "Mode.text",
                                                          stringified: false,
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 512)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, {
                                              color: "blue darken-1",
                                              text: "",
                                              onClick: $options.close
                                            }, {
                                              default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                _createTextVNode("Cancel")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick"]),
                                            _createVNode(_component_v_btn, {
                                              color: "blue darken-1",
                                              text: "",
                                              onClick: $options.createPipeline,
                                              disabled: !$data.newDescription || !$data.newName
                                            }, {
                                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                                _createTextVNode("OK")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick", "disabled"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createVNode(_component_v_dialog, {
                          persistent: "",
                          modelValue: $data.dialog,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($data.dialog) = $event)),
                          "max-width": "850px"
                        }, {
                          default: _withCtx(() => [
                            ($data.dialog)
                              ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                                        _createElementVNode("span", { class: "headline" }, "Edit Pipeline", -1)
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_form, { ref: "createForm" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_container, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $data.editedName,
                                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.editedName) = $event)),
                                                          density: "compact",
                                                          label: "Pipeline Name",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      class: "pt-0 pb-0",
                                                      cols: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_combobox, {
                                                          class: "mt-1",
                                                          modelValue: $data.editedTags,
                                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.editedTags) = $event)),
                                                          placeholder: "Click to Add Pipeline Dependencies",
                                                          multiple: "",
                                                          "small-chips": "",
                                                          "hide-details": "",
                                                          density: "compact",
                                                          flat: "",
                                                          "deletable-chips": "",
                                                          items: $options.tagOptions
                                                        }, null, 8, ["modelValue", "items"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { class: "pb-0 mb-0" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "9" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, {
                                                          class: "mt-1",
                                                          label: "Click to Add Description",
                                                          modelValue: $data.editedDescription,
                                                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.editedDescription) = $event)),
                                                          density: "compact",
                                                          flat: "",
                                                          "hide-details": "",
                                                          maxlength: "200",
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, { cols: "3" }, {
                                                      default: _withCtx(() => [
                                                        ($options.globalPermission)
                                                          ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                                              key: 0,
                                                              label: "Global Pipeline",
                                                              density: "compact",
                                                              modelValue: $data.editedGlobal,
                                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($data.editedGlobal) = $event))
                                                            }, null, 8, ["modelValue"]))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { class: "no-gutters" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        ($options.hasBoundingBoxEdit)
                                                          ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
                                                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                                _createTextVNode("Supports the following dependencies: [<%xmin%>,<%xmax%>],[<%ymin%>,<%ymax%>], [<%zmin%>,<%zmax%>]")
                                                              ])),
                                                              _: 1
                                                            }))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { class: "pt-0 mt-0" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_JsonEditorVue, {
                                                          modelValue: $data.editedPipeline,
                                                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.editedPipeline) = $event)),
                                                          mode: "Mode.text",
                                                          stringified: false,
                                                          rules: [$data.rules.required]
                                                        }, null, 8, ["modelValue", "rules"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 512)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, {
                                              color: "blue darken-1",
                                              text: "",
                                              onClick: $options.closeDelete
                                            }, {
                                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                _createTextVNode("Cancel")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick"]),
                                            _createVNode(_component_v_btn, {
                                              color: "blue darken-1",
                                              text: "",
                                              onClick: $options.editPipeline
                                            }, {
                                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                _createTextVNode("OK")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["headers", "items", "search"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}