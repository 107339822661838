import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 d-flex",
  id: "admin-projects"
}
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { style: {"white-space":"nowrap"} }
const _hoisted_4 = ["colspan"]
const _hoisted_5 = { class: "headline" }
const _hoisted_6 = { style: {"display":"flex","flex-direction":"row"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100vw" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[31] || (_cache[31] = _createTextVNode("Projects")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-0",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              density: "compact",
              "hide-details": "",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_data_table, {
          headers: $data.headers,
          "sort-by": ['name'],
          "group-by": $data.groupBy,
          items: _ctx.projects || [],
          "item-key": "id",
          search: $data.search,
          loading: $options.loading,
          width: "800px",
          "loading-text": "Loading... Please wait",
          "footer-props": {"items-per-page-options":[25,50,100,-1]},
          "items-per-page": 100
        }, {
          "group-header": _withCtx(({ item, columns, toggleGroup, isGroupOpen }) => [
            _createElementVNode("tr", {
              width: "100%",
              ref: (el)=>{if ($options.loading && !isGroupOpen(item)) { toggleGroup(item)}}
            }, [
              _createElementVNode("td", {
                colspan: columns.length,
                "bg-color": "grey-darken-2"
              }, [
                _createVNode(_component_v_btn, {
                  size: "small",
                  variant: "text",
                  icon: isGroupOpen(item) ? '$expand' : '$next',
                  onClick: ($event: any) => (toggleGroup(item))
                }, null, 8, ["icon", "onClick"]),
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.value.name), 1)
              ], 8, _hoisted_2)
            ], 512)
          ]),
          "expanded-row": _withCtx(({ headers, item }) => [
            _createElementVNode("td", {
              colspan: headers.length
            }, "Comments: " + _toDisplayString(item.comments), 9, _hoisted_4)
          ]),
          "item.active": _withCtx(({ item }) => [
            _createVNode(_component_v_checkbox, {
              modelValue: item.active,
              "onUpdate:modelValue": ($event: any) => ((item.active) = $event),
              disabled: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          "item.project": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          "item.organization": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(item.organization.name), 1)
          ]),
          "item.from": _withCtx(({ item }) => [
            (item.from === null)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("no device assignment ")
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(new Date(item.from).toLocaleString()), 1)
                ], 64))
          ]),
          "item.to": _withCtx(({ item }) => [
            (item.to === null)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(new Date(item.to).toLocaleString()), 1)
                ], 64))
          ]),
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, {
              flat: "",
              color: "transparent"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialog,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => (($data.dialog) = $event)),
                  "max-width": "850px"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_icon, _mergeProps({
                      class: "mr-2",
                      color: "primary lighten-2"
                    }, props), {
                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                        _createTextVNode("mdi-plus-circle")
                      ])),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    ($data.dialog)
                      ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_5, _toDisplayString($options.formTitle), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_form, {
                                  modelValue: $data.isFormValid,
                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (($data.isFormValid) = $event)),
                                  ref: "createForm",
                                  "validate-on": "eager"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_container, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "5" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.name,
                                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.editedItem.name) = $event)),
                                                  density: "compact",
                                                  label: "Project Name",
                                                  rules: [$data.rules.required]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { cols: "5" }, {
                                              default: _withCtx(() => [
                                                (!_ctx.isPowerUser)
                                                  ? (_openBlock(), _createBlock(_component_v_select, {
                                                      key: 0,
                                                      density: "compact",
                                                      label: "Organization",
                                                      modelValue: $data.editedItem.organization,
                                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.editedItem.organization) = $event)),
                                                      items: _ctx.organizations,
                                                      "item-title": 'name',
                                                      "item-value": 'id',
                                                      "return-object": "",
                                                      rules: [$data.rules.required]
                                                    }, null, 8, ["modelValue", "items", "rules"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { cols: "2" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_checkbox, {
                                                  label: "Active",
                                                  density: "compact",
                                                  modelValue: $data.editedItem.active,
                                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.editedItem.active) = $event))
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, { class: "no-gutters" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_textarea, {
                                                  modelValue: $data.editedItem.comments,
                                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.editedItem.comments) = $event)),
                                                  density: "compact",
                                                  clearable: "",
                                                  rows: "2",
                                                  label: "Comments"
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, { class: "no-gutters" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_subtitle, null, {
                                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                                    _createTextVNode("Specified UTM is required as XX"),
                                                    _createElementVNode("b", null, "N", -1),
                                                    _createTextVNode(" (North) or XX"),
                                                    _createElementVNode("b", null, "S", -1),
                                                    _createTextVNode(" (South).")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { cols: "2" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.utm,
                                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.editedItem.utm) = $event)),
                                                  density: "compact",
                                                  label: "UTM",
                                                  rules: [$data.rules.required]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, { class: "mt-0 pt-0" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, {
                                              class: "ma-0 pa-0",
                                              cols: "12"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_tabs, {
                                                  class: "ma-0 pa-0 pl-3",
                                                  modelValue: $data.selectedTab,
                                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.selectedTab) = $event))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_tab, null, {
                                                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                                                        _createTextVNode("Transform")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_tab, null, {
                                                      default: _withCtx(() => _cache[35] || (_cache[35] = [
                                                        _createTextVNode("Classification")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                              default: _withCtx(() => [
                                                ($data.selectedTab === 0)
                                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                      _cache[39] || (_cache[39] = _createTextVNode("If the proj4 projection string is left blank, no projection will be performed. Geoid grids file and Helmert parameters are optional. If your required geoid grid file is not in the list, please let us know.")),
                                                      _createVNode(_component_v_row, { class: "pa-3 mt-2" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_col, { cols: "9" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                label: "proj4 projection",
                                                                density: "compact",
                                                                clearable: "",
                                                                "hide-details": "",
                                                                modelValue: $data.editedItem.projection,
                                                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.editedItem.projection) = $event))
                                                              }, null, 8, ["modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, { cols: "3" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_select, {
                                                                density: "compact",
                                                                clearable: "",
                                                                label: "Geoid grids",
                                                                modelValue: $data.editedItem.geoidGridFile,
                                                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.editedItem.geoidGridFile) = $event)),
                                                                items: _ctx.geoidGridFiles || []
                                                              }, null, 8, ["modelValue", "items"])
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      }),
                                                      _createVNode(_component_v_row, null, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_col, { cols: "3" }, {
                                                            default: _withCtx(() => _cache[36] || (_cache[36] = [
                                                              _createTextVNode("Helmert Parameters")
                                                            ])),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, { cols: "4" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                label: "reference name",
                                                                modelValue: $data.editedItem.helmertParameters.name,
                                                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.editedItem.helmertParameters.name) = $event)),
                                                                density: "compact",
                                                                "hide-details": ""
                                                              }, null, 8, ["modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            class: "d-flex",
                                                            cols: "5"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_spacer),
                                                              _createVNode(_component_v_btn, {
                                                                plain: "",
                                                                depressed: "",
                                                                onClick: _cache[10] || (_cache[10] = ($event: any) => ($options.prefillHelmertParameters()))
                                                              }, {
                                                                default: _withCtx(() => _cache[37] || (_cache[37] = [
                                                                  _createTextVNode("prefill example")
                                                                ])),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_v_btn, {
                                                                plain: "",
                                                                depressed: "",
                                                                onClick: _cache[11] || (_cache[11] = ($event: any) => ($options.resetHelmertParameters()))
                                                              }, {
                                                                default: _withCtx(() => _cache[38] || (_cache[38] = [
                                                                  _createTextVNode("reset")
                                                                ])),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      }),
                                                      _createVNode(_component_v_row, null, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_col, { cols: "3" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                label: "x",
                                                                modelValue: $data.editedItem.helmertParameters.Dx,
                                                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($data.editedItem.helmertParameters.Dx) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "y",
                                                                modelValue: $data.editedItem.helmertParameters.Dy,
                                                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($data.editedItem.helmertParameters.Dy) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "z",
                                                                modelValue: $data.editedItem.helmertParameters.Dz,
                                                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($data.editedItem.helmertParameters.Dz) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "s",
                                                                modelValue: $data.editedItem.helmertParameters.M,
                                                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($data.editedItem.helmertParameters.M) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, { cols: "3" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                label: "dx",
                                                                modelValue: $data.editedItem.helmertParameters.d_Dx,
                                                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($data.editedItem.helmertParameters.d_Dx) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "dy",
                                                                modelValue: $data.editedItem.helmertParameters.d_Dy,
                                                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($data.editedItem.helmertParameters.d_Dy) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "dz",
                                                                modelValue: $data.editedItem.helmertParameters.d_Dz,
                                                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($data.editedItem.helmertParameters.d_Dz) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "ds",
                                                                modelValue: $data.editedItem.helmertParameters.d_M,
                                                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($data.editedItem.helmertParameters.d_M) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, { cols: "3" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                label: "rx",
                                                                modelValue: $data.editedItem.helmertParameters.Rx,
                                                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($data.editedItem.helmertParameters.Rx) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "ry",
                                                                modelValue: $data.editedItem.helmertParameters.Ry,
                                                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($data.editedItem.helmertParameters.Ry) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "rz",
                                                                modelValue: $data.editedItem.helmertParameters.Rz,
                                                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($data.editedItem.helmertParameters.Rz) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "reference epoch",
                                                                modelValue: $data.editedItem.helmertParameters.reference_year,
                                                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($data.editedItem.helmertParameters.reference_year) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, { cols: "3" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_text_field, {
                                                                label: "drx",
                                                                modelValue: $data.editedItem.helmertParameters.d_Rx,
                                                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($data.editedItem.helmertParameters.d_Rx) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "dry",
                                                                modelValue: $data.editedItem.helmertParameters.d_Ry,
                                                                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($data.editedItem.helmertParameters.d_Ry) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"]),
                                                              _createVNode(_component_v_text_field, {
                                                                label: "drz",
                                                                modelValue: $data.editedItem.helmertParameters.d_Rz,
                                                                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($data.editedItem.helmertParameters.d_Rz) = $event)),
                                                                "hide-details": "",
                                                                type: "number"
                                                              }, null, 8, ["modelValue"])
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ], 64))
                                                  : _createCommentVNode("", true),
                                                ($data.selectedTab === 1)
                                                  ? (_openBlock(), _createBlock(_component_v_row, {
                                                      key: 1,
                                                      class: "pa-3"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_col, { cols: "12" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_combobox, {
                                                              modelValue: $data.editedItem.tagOptions,
                                                              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (($data.editedItem.tagOptions) = $event)),
                                                              label: "Tag Options",
                                                              placeholder: "click to add tags options for classifying scans",
                                                              multiple: "",
                                                              "small-chips": "",
                                                              "hide-details": "",
                                                              density: "compact",
                                                              flat: "",
                                                              "deletable-chips": ""
                                                            }, null, 8, ["modelValue"])
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.close
                                        }, {
                                          default: _withCtx(() => _cache[40] || (_cache[40] = [
                                            _createTextVNode("Cancel")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick"]),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.save,
                                          disabled: !$data.isFormValid
                                        }, {
                                          default: _withCtx(() => _cache[41] || (_cache[41] = [
                                            _createTextVNode("Save")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialogDelete,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($data.dialogDelete) = $event)),
                  "max-width": "500px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "headline" }, {
                          default: _withCtx(() => [
                            _cache[42] || (_cache[42] = _createTextVNode("Confirm Project Deletion")),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Are you sure you want to delete project '" + _toDisplayString($data.editedItem.name) + "'?", 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              color: "blue darken-1",
                              text: "",
                              onClick: $options.closeDelete
                            }, {
                              default: _withCtx(() => _cache[43] || (_cache[43] = [
                                _createTextVNode("Cancel")
                              ])),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              color: "blue darken-1",
                              text: "",
                              onClick: $options.deleteItemConfirm
                            }, {
                              default: _withCtx(() => _cache[44] || (_cache[44] = [
                                _createTextVNode("OK")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          "item.actions": _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_icon, {
                class: "mr-2",
                small: "",
                onClick: ($event: any) => ($options.editItem(item))
              }, {
                default: _withCtx(() => _cache[45] || (_cache[45] = [
                  _createTextVNode("mdi-pencil")
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_v_icon, {
                small: "",
                onClick: ($event: any) => ($options.deleteItem(item))
              }, {
                default: _withCtx(() => _cache[46] || (_cache[46] = [
                  _createTextVNode("mdi-delete")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["headers", "group-by", "items", "search", "loading"])
      ]),
      _: 1
    })
  ]))
}