const snackbarPlugin = {
  install(app, { store }) {
    if (!store) {
      throw new Error(
        "Please provide a Vuex store via plugin options: { store }."
      );
    }

    const $snackbar = {
      showMessage(content = "", color = "", timeout = 8000) {
        store.commit(
          "showMessage",
          { content, color, timeout },
          { root: true }
        );
      },
    };

    app.config.globalProperties.$snackbar = $snackbar;
  },
};

export default snackbarPlugin;
