import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "admin-bulk-processing"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ScanFilter = _resolveComponent("ScanFilter")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createTextVNode(" Bulk Processing")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_subtitle, { class: "pa-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_ScanFilter)
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              "single-select": false,
              headers: $data.headers,
              "sort-by": $data.sortColumn,
              "sort-desc": $data.sortDescending,
              items: _ctx.filteredSummaryScanList || [],
              "item-key": "id",
              search: $data.search,
              loading: _ctx.isLoading,
              width: "800px",
              "loading-text": "Loading... Please wait",
              "footer-props": {"items-per-page-options":[25,50,100,-1]},
              "items-per-page": 25
            }, {
              top: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  flat: "",
                  density: "compact",
                  color: "transparent"
                }, {
                  default: _withCtx(() => [
                    (!$data.allSelected)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          icon: "",
                          onClick: $options.selectAll
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("mdi-checkbox-blank-outline")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    ($data.allSelected)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 1,
                          icon: "",
                          onClick: $options.unselectAll
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("mdi-checkbox-marked")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_card_subtitle, { class: "pa-0" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Select All")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    (_ctx.project !== null && _ctx.projectId !== -1 && _ctx.selectedScanIdList && _ctx.selectedScanIdList.length !== 0)
                      ? (_openBlock(), _createBlock(_component_v_menu, {
                          key: 2,
                          "offset-y": ""
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("mdi-cloud-sync-outline")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, null, {
                              default: _withCtx(() => [
                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['Process', 'Publish'], (item) => {
                                  return _createVNode(_component_v_list_item, {
                                    key: item,
                                    onClick: ($event: any) => ($options.openProcessOption(item))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                }), 64))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($data.dialog)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.processingComponent), {
                          key: 3,
                          onFinished: $options.closeProcessOption
                        }, null, 40, ["onFinished"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              "item.select": _withCtx(({ item }) => [
                (!_ctx.isScanSelected(item.id))
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      icon: "",
                      disabled: !$options.scanHasPointCloud(item),
                      onClick: ($event: any) => ($options.selectScan(item.id)),
                      flat: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("mdi-checkbox-blank-outline")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.isScanSelected(item.id))
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      icon: "",
                      onClick: ($event: any) => ($options.unselectScan(item.id)),
                      flat: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("mdi-checkbox-marked")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              "item.id": _withCtx(({ item }) => [
                _createElementVNode("b", null, _toDisplayString(_ctx.getScanName(item)), 1),
                _cache[20] || (_cache[20] = _createTextVNode()),
                (item.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.$filters.asFloat(item.length)) + " meters)", 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_tooltip, { location: "bottom" }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps(props, {
                      size: "x-small",
                      icon: "",
                      flat: ""
                    }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("mdi-information-outline")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, {
                      class: "pa-0 ma-0",
                      width: "150px"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Session Id: " + _toDisplayString(item.sessionId), 1),
                        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode("Trajectory Orientation: " + _toDisplayString($options.parseOrientation(item.metadata).trajectory_orientation), 1),
                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode("Device Orientation: " + _toDisplayString($options.parseOrientation(item.metadata).device_orientation), 1),
                        _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode("Device Position: " + _toDisplayString($options.parseOrientation(item.metadata).device_position), 1),
                        _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode("Last Processed: " + _toDisplayString(item.lastModified), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                ($options.scanHasImages(item))
                  ? (_openBlock(), _createBlock(_component_v_tooltip, {
                      key: 1,
                      class: "pl-3",
                      location: "bottom"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps(props, {
                          size: "x-small",
                          icon: "",
                          flat: ""
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("mdi-camera")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, {
                          class: "pa-0 ma-0",
                          width: "150px"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Scan includes images for " + _toDisplayString($options.scanImageList(item)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                ($options.scanStatusFinal(item) && $options.scanHasPointCloud(item))
                  ? (_openBlock(), _createBlock(_component_v_tooltip, {
                      key: 2,
                      location: "bottom",
                      color: "green"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps(props, {
                          size: "x-small",
                          icon: "",
                          flat: ""
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { color: "green" }, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createTextVNode("mdi-checkbox-marked-circle")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, {
                          class: "pa-0 ma-0",
                          width: "150px"
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode("Scan successfully processed to final")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!$options.scanStatusFinal(item) && $options.scanHasPointCloud(item))
                  ? (_openBlock(), _createBlock(_component_v_tooltip, {
                      key: 3,
                      location: "bottom",
                      color: "warning"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps(props, {
                          size: "x-small",
                          icon: "",
                          flat: ""
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { color: "warning" }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode("mdi-alert")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, {
                          class: "pa-0 ma-0",
                          width: "150px"
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("Scan processed with provisional trajectory, base station data is required for final processing.")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!$options.scanHasPointCloud(item))
                  ? (_openBlock(), _createBlock(_component_v_tooltip, {
                      key: 4,
                      location: "bottom",
                      color: "red"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps(props, {
                          size: "x-small",
                          icon: "",
                          flat: ""
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { color: "red" }, {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createTextVNode("mdi-alert-octagon")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, {
                          class: "pa-0 ma-0",
                          width: "150px"
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("Scan could not be processed, or was not uploaded")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              "item.friendlyLocationName": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(item.friendlyLocationName), 1)
              ]),
              "item.capturedTimestamp": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.$filters.utcDateTime(item.capturedTimestamp)) + " ", 1),
                _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                _createTextVNode("(" + _toDisplayString(item.capturedTimestamp) + ")", 1)
              ]),
              _: 1
            }, 8, ["headers", "sort-by", "sort-desc", "items", "search", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}