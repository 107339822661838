import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 d-flex",
  id: "package-upload"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_ActivityLogList = _resolveComponent("ActivityLogList")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.showUploadConfigDialog,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showUploadConfigDialog) = $event)),
              "max-width": "400px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Advanced Upload Options")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "mt-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_slider, {
                                  modelValue: _ctx.maxUploadWorkers,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.maxUploadWorkers) = $event)),
                                  min: "1",
                                  max: "10",
                                  density: "compact",
                                  step: "1",
                                  label: "Simultaneous Uploads",
                                  "thumb-label": ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  modelValue: _ctx.multiPartUpload,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.multiPartUpload) = $event)),
                                  density: "compact",
                                  label: "Use Multipart Uploads"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "blue darken-1",
                          text: "",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showUploadConfigDialog = false))
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          color: "blue darken-1",
                          text: "",
                          onClick: $options.updateConfig
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Ok")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.showExistingFileDialog,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showExistingFileDialog) = $event)),
              width: "600px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode("Redundant upload")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, { class: "pt-2" }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode("Some of the following packages are already on the server. Select any you wish to force upload.")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                ($options.countOfConfirmedFilesToUpload < _ctx.userConfirmedFiles.length)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      class: "mr-2",
                                      color: "blue darken-1",
                                      text: "",
                                      size: "small",
                                      onClick: $options.replaceAllExistingFiles
                                    }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode("Select all")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"]))
                                  : _createCommentVNode("", true),
                                ($options.countOfAlreadyExistingFilesToUpload > 0)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 1,
                                      size: "small",
                                      onClick: $options.skipAllExistingFiles
                                    }, {
                                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                                        _createTextVNode("Skip all existing file(s)")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, {
                                  dense: "",
                                  "max-height": "400"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userConfirmedFiles, (f) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                        key: f.name,
                                        title: f.name,
                                        value: f.name,
                                        disabled: !f.alreadyExists
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_action, { start: "" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_checkbox, {
                                                modelValue: f.isConfirmedForUpload,
                                                "onUpdate:modelValue": ($event: any) => ((f.isConfirmedForUpload) = $event),
                                                disabled: !f.alreadyExists,
                                                style: {"margin-bottom":"-20px"}
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                                              _createElementVNode("span", null, _toDisplayString($options.formatBytes(f.file.size)), 1),
                                              (f.alreadyExists && (f.file.size !== f.uploadedSize))
                                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                                    key: 0,
                                                    class: "error ml-2",
                                                    small: ""
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString($options.formatBytes(f.uploadedSize)) + " on server", 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                : _createCommentVNode("", true),
                                              (f.alreadyExists && (f.file.size === f.uploadedSize))
                                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                                    key: 1,
                                                    class: "warning ma-2",
                                                    small: ""
                                                  }, {
                                                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                      _createTextVNode("Already uploaded")
                                                    ])),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["title", "value", "disabled"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "blue darken-1",
                          text: "",
                          onClick: $options.cancelUpload
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          color: "blue darken-1",
                          text: "",
                          onClick: $options.startUploadOfConfirmedFiles,
                          disabled: $options.countOfConfirmedFilesToUpload === 0
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Upload " + _toDisplayString($options.countOfConfirmedFilesToUpload) + " File(s)", 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              modelValue: _ctx.isFormValid,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isFormValid) = $event)),
              ref: "createForm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  class: "ma-2",
                  width: "750px",
                  style: {"display":"flex","flex-direction":"column","box-sizing":"border-box","padding":"20px"},
                  onDrop: _withModifiers($options.addDropFile, ["prevent"]),
                  onDragover: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("LuxGear Package Upload")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createTextVNode("Files from the LuxGear device ("),
                        _createElementVNode("b", null, ".luxlz or .luxiz", -1),
                        _createTextVNode("). These are likely in the 'Documents/Lux Modus Data' folder on the control computer. "),
                        _createElementVNode("br", null, null, -1),
                        _createElementVNode("i", null, "Note: when network bandwidth is low or unreliable, MASV is recommended.", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_file_input, {
                      class: "p0",
                      accept: ".luxlz,.luxiz",
                      modelValue: _ctx.userSelectedFiles,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userSelectedFiles) = $event)),
                      "truncate-length": "15",
                      "show-size": "",
                      multiple: "",
                      style: {"font-size":"12px !important","width":"100%"},
                      rules: _ctx.fileInputRules
                    }, {
                      selection: _withCtx(() => [
                        _createTextVNode(_toDisplayString($options.filesSummary.count) + " files (" + _toDisplayString($options.formattedFileSize) + ")", 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "rules"]),
                    _createVNode(_component_v_card_actions, { "hide-details": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "ml-2",
                          icon: "",
                          small: "",
                          color: "primary",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showUploadConfigDialog = true))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("mdi-cog-outline")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "blue darken-1",
                          text: "",
                          onClick: $options.confirmUploadFile,
                          disabled: !_ctx.isFormValid
                        }, {
                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                            _createTextVNode("Upload")
                          ])),
                          _: 1
                        }, 8, ["onClick", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onDrop"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "ml-3",
              flat: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.tab) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tab, { href: "#progress-tab" }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("Progress")
                      ])),
                      _: 1
                    }),
                    (_ctx.uploadErrorsCount > 0)
                      ? (_openBlock(), _createBlock(_component_v_tab, {
                          key: 0,
                          href: "#errors-tab"
                        }, {
                          default: _withCtx(() => [
                            _cache[25] || (_cache[25] = _createTextVNode("Upload Errors")),
                            (_ctx.uploadErrorsCount > 0)
                              ? (_openBlock(), _createBlock(_component_v_badge, {
                                  key: 0,
                                  color: "red",
                                  content: _ctx.uploadErrorsCount
                                }, null, 8, ["content"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_tab, { href: "#history-tab" }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createTextVNode("History")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_tabs_window, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.tab) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tabs_window_item, { value: "progress-tab" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { flat: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => _cache[27] || (_cache[27] = [
                                _createElementVNode("i", null, "Packages are uploaded in the background. You can continue to use LuxWeb while they are being uploaded, but do not close the browser.", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                (!_ctx.queuedOrActiveUploadCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "No files are currently being uploaded."))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.overallProgressDisplay), 1)),
                                (_ctx.queuedOrActiveUploadCount)
                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList($options.sortedWorkerInfoList, (workerInfo, index) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                        class: "ma-0",
                                        "two-line": "",
                                        key: `${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_title, null, {
                                            default: _withCtx(() => [
                                              (workerInfo.state.isComplete)
                                                ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                                                    key: 0,
                                                    modelValue: workerInfo.progressMonitor.progress,
                                                    "onUpdate:modelValue": ($event: any) => ((workerInfo.progressMonitor.progress) = $event),
                                                    color: "success"
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                : (workerInfo.state.hasError)
                                                  ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                                                      key: 1,
                                                      modelValue: workerInfo.progressMonitor.progress,
                                                      "onUpdate:modelValue": ($event: any) => ((workerInfo.progressMonitor.progress) = $event),
                                                      color: "error"
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                  : (_openBlock(), _createBlock(_component_v_progress_linear, {
                                                      key: 2,
                                                      modelValue: workerInfo.progressMonitor.progress,
                                                      "onUpdate:modelValue": ($event: any) => ((workerInfo.progressMonitor.progress) = $event)
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_v_list_item_subtitle, null, {
                                            default: _withCtx(() => [
                                              (workerInfo.state.isComplete)
                                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                                    key: 0,
                                                    class: "mr-2",
                                                    color: "green"
                                                  }, {
                                                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                                                      _createElementVNode("span", null, "Complete", -1)
                                                    ])),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true),
                                              (workerInfo.state.isUploading)
                                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                                    key: 1,
                                                    class: "mr-2",
                                                    color: "primary"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("span", null, _toDisplayString(Math.round(workerInfo.progressMonitor.progress)) + "%", 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                : _createCommentVNode("", true),
                                              (workerInfo.state.hasError)
                                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                                    key: 2,
                                                    class: "mr-2",
                                                    color: "error"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("span", null, "Error (" + _toDisplayString(Math.round(workerInfo.progressMonitor.progress)) + "% complete)", 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                : _createCommentVNode("", true),
                                              (!workerInfo.state.hasStarted)
                                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                                    key: 3,
                                                    class: "mr-2"
                                                  }, {
                                                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                                                      _createElementVNode("span", null, "In Queue", -1)
                                                    ])),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true),
                                              (!$options.isMultiPartUploadType(workerInfo))
                                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                                    key: 4,
                                                    small: ""
                                                  }, {
                                                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                                                      _createTextVNode("mdi-file-upload")
                                                    ])),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true),
                                              ($options.isMultiPartUploadType(workerInfo))
                                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                                    key: 5,
                                                    small: ""
                                                  }, {
                                                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                                                      _createTextVNode("mdi-table-arrow-up")
                                                    ])),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true),
                                              _createElementVNode("span", null, _toDisplayString(workerInfo.name), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.uploadErrorsCount > 0)
                      ? (_openBlock(), _createBlock(_component_v_tabs_window_item, {
                          key: 0,
                          value: "errors-tab"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, { flat: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_subtitle, null, {
                                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                                    _createTextVNode("Errors with package uploads that you have started will appear here")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ActivityLogList, {
                                      activityTypeFilter: "PackageUpload",
                                      statusFilter: "Error",
                                      userIdFilter: Number(_ctx.user ? _ctx.user.id : 0),
                                      isAcknowledgedFilter: "false",
                                      columnVisibility: $options.uploadErrorsColumns,
                                      autoRefresh: _ctx.hasQueuedOrActiveUploads
                                    }, null, 8, ["userIdFilter", "columnVisibility", "autoRefresh"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_tabs_window_item, { value: "history-tab" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { flat: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => _cache[33] || (_cache[33] = [
                                _createTextVNode("Your full package upload history will appear here")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ActivityLogList, {
                                  activityTypeFilter: "PackageUpload",
                                  userIdFilter: Number(_ctx.user ? _ctx.user.id : 0),
                                  isAcknowledgedFilter: "false",
                                  columnVisibility: $options.uploadHistoryColumns,
                                  autoRefresh: _ctx.hasQueuedOrActiveUploads
                                }, null, 8, ["userIdFilter", "columnVisibility", "autoRefresh"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}