import {
  addWindowCloseBlock,
  removeWindowCloseBlock,
} from "../utils/file-upload-utils";

export const fileUploadMixin = {
  data() {
    return {
      file: null,
      fileUploadPercentage: 0,
      uploadingFile: false,
      singleLasFileInputRules: [
        (v) => {
          const file = Array.isArray(v) ? v[0] : v;
          return (
            (file && file.name?.endsWith(".las")) ||
            "files type must be LAS with a .las extension"
          );
        },
        (v) => {
          const file = Array.isArray(v) ? v[0] : v;
          return (file && file.size !== 0) || "File size cannot be empty";
        },
      ],
      singleBasestationFileInputRules: [
        (v) => {
          const file = Array.isArray(v) ? v[0] : v;
          return (
            (file && file.name != null) ||
            `File is required, got: ${JSON.stringify(file && file.name)}`
          );
        },
        (v) => {
          const file = Array.isArray(v) ? v[0] : v;
          return (
            (file && file.size > 0) ||
            `File is required, got size: ${JSON.stringify(file && file.size)}`
          );
        },
        (v) => {
          const file = Array.isArray(v) ? v[0] : v;
          return (
            (file && file.name?.indexOf(" ") === -1) ||
            "Filename cannot have spaces"
          );
        },
      ],
    };
  },
  computed: {
    showUpload() {
      return ![0, 100].includes(this.fileUploadPercentage);
    },
  },
  methods: {
    addOneDropFile(e) {
      this.file = Array.from(e.dataTransfer.files)[0];
      console.log("this.file", this.file);
    },
    addAllDropFiles(e) {
      this.userSelectedFiles = Array.from(e.dataTransfer.files);
      console.log("this.userSelectedFiles", this.userSelectedFiles);
    },
    addWindowCloseBlock() {
      this.$snackbar.showMessage(
        "Do not close your browser while files are uploading. A close-block has been added to warn if you attempt to reload the page or close the tab",
        "warning"
      );
      addWindowCloseBlock();
    },
    removeWindowCloseBlock() {
      removeWindowCloseBlock();
    },
  },
  watch: {
    file(newVal) {
      console.log("file changed:", newVal);
      console.log("is array?", Array.isArray(newVal));
      if (Array.isArray(newVal)) {
        console.log("first file name:", newVal[0]?.name);
      } else {
        console.log("file name:", newVal?.name);
      }
    },
  },
};
