import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 d-flex",
  id: "admin-users"
}
const _hoisted_2 = { class: "headline" }
const _hoisted_3 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row"} }, {
          default: _withCtx(() => [
            _cache[18] || (_cache[18] = _createTextVNode("Users")),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text_field, {
              class: "pa-0 ma-1",
              modelValue: $data.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              density: "compact",
              style: {"max-width":"20vw"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_data_table, {
          headers: $data.headers,
          "sort-by": ['name'],
          "group-by": $data.groupBy,
          items: _ctx.orgUsers || [],
          "item-key": "id",
          search: $data.search,
          loading: $options.loading,
          "hide-group-header": "",
          "group-options": { showGroupHeader: false },
          width: "800px",
          "loading-text": "Loading... Please wait",
          "footer-props": {"items-per-page-options":[25,50,100,-1]},
          "items-per-page": 25
        }, {
          "item.enabled": _withCtx(({ item }) => [
            _createVNode(_component_v_checkbox, {
              modelValue: item.enabled,
              "onUpdate:modelValue": ($event: any) => ((item.enabled) = $event),
              disabled: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, {
              flat: "",
              color: "transparent"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialogCreate,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.dialogCreate) = $event)),
                  "max-width": "550px"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_icon, _mergeProps({
                      class: "mr-2",
                      color: "primary lighten-2"
                    }, props), {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("mdi-plus-circle")
                      ])),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    ($data.dialogCreate)
                      ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_2, _toDisplayString($options.formTitle), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_form, {
                                  modelValue: $data.isCreateValid,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.isCreateValid) = $event)),
                                  ref: "createForm"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_container, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "6" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.name,
                                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.editedItem.name) = $event)),
                                                  density: "compact",
                                                  label: "Full Name",
                                                  onInput: _cache[2] || (_cache[2] = ($event: any) => ($options.setUsername($data.editedItem))),
                                                  rules: [$data.rules.required]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { cols: "6" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.username,
                                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.editedItem.username) = $event)),
                                                  density: "compact",
                                                  label: "Username (permanent)",
                                                  rules: [$data.rules.required]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.email,
                                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.editedItem.email) = $event)),
                                                  density: "compact",
                                                  label: "Email (permanent)",
                                                  "prepend-icon": "mdi-email",
                                                  hint: "amy@example.com",
                                                  rules: [$data.rules.required, $data.rules.emailValid]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: $data.editedItem.phone,
                                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.editedItem.phone) = $event)),
                                                  density: "compact",
                                                  label: "Phone",
                                                  "prepend-icon": "mdi-phone",
                                                  hint: "123-456-7890, (123) 456-7890, 123.456.7890, +91 (123) 456-7890, etc.",
                                                  rules: [$data.rules.phoneValid]
                                                }, null, 8, ["modelValue", "rules"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_select, {
                                                  density: "compact",
                                                  label: "Organization",
                                                  modelValue: $data.editedItem.organization,
                                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.editedItem.organization) = $event)),
                                                  "prepend-icon": "mdi-home-city-outline",
                                                  items: _ctx.organizations || [],
                                                  "item-title": 'name',
                                                  "item-value": 'id',
                                                  "return-object": ""
                                                }, null, 8, ["modelValue", "items"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_card_actions, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.closeCreate
                                        }, {
                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                            _createTextVNode("Cancel")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick"]),
                                        _createVNode(_component_v_btn, {
                                          color: "blue darken-1",
                                          text: "",
                                          onClick: $options.commitCreate,
                                          disabled: !$data.isCreateValid && $data.editedItem.organization
                                        }, {
                                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                                            _createTextVNode("Invite User")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "disabled"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialogEdit,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($data.dialogEdit) = $event)),
                  "max-width": "550px"
                }, {
                  default: _withCtx(() => [
                    ($data.dialogEdit)
                      ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_3, _toDisplayString($options.formTitle), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_container, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: $data.editedItem.name,
                                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.editedItem.name) = $event)),
                                              density: "compact",
                                              label: "Full Name"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: $data.editedItem.username,
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($data.editedItem.username) = $event)),
                                              density: "compact",
                                              disabled: "",
                                              label: "Username",
                                              rules: [$data.rules.required]
                                            }, null, 8, ["modelValue", "rules"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "10" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: $data.editedItem.email,
                                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.editedItem.email) = $event)),
                                              density: "compact",
                                              disabled: "",
                                              label: "Email",
                                              "prepend-icon": "mdi-email",
                                              hint: "amy@example.com",
                                              rules: [$data.rules.emailValid]
                                            }, null, 8, ["modelValue", "rules"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "10" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: $data.editedItem.phone,
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($data.editedItem.phone) = $event)),
                                              density: "compact",
                                              label: "Phone",
                                              "prepend-icon": "mdi-phone",
                                              hint: "123-456-7890, (123) 456-7890, 123.456.7890, +91 (123) 456-7890, etc.",
                                              rules: [$data.rules.phoneValid]
                                            }, null, 8, ["modelValue", "rules"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_select, {
                                              density: "compact",
                                              label: "Organization",
                                              modelValue: $data.editedItem.organization,
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($data.editedItem.organization) = $event)),
                                              "prepend-icon": "mdi-home-city-outline",
                                              items: _ctx.organizations,
                                              "item-title": 'name',
                                              "item-value": 'id',
                                              "return-object": ""
                                            }, null, 8, ["modelValue", "items"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_select, {
                                              density: "compact",
                                              label: "User Type",
                                              modelValue: $data.editedItem.userType,
                                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($data.editedItem.userType) = $event)),
                                              items: $options.userTypes
                                            }, null, 8, ["modelValue", "items"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "4" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_checkbox, {
                                              class: "shrink mr-0 mt-0",
                                              label: "Enabled",
                                              "hide-details": "",
                                              density: "compact",
                                              modelValue: $data.editedItem.enabled,
                                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($data.editedItem.enabled) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_actions, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_spacer),
                                    _createVNode(_component_v_btn, {
                                      color: "blue darken-1",
                                      text: "",
                                      onClick: $options.closeEdit
                                    }, {
                                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                                        _createTextVNode("Cancel")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"]),
                                    _createVNode(_component_v_btn, {
                                      color: "blue darken-1",
                                      text: "",
                                      onClick: $options.commitEdit
                                    }, {
                                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                                        _createTextVNode("Save")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_dialog, {
                  persistent: "",
                  modelValue: $data.dialogDelete,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($data.dialogDelete) = $event)),
                  "max-width": "500px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "headline" }, {
                          default: _withCtx(() => [
                            _cache[24] || (_cache[24] = _createTextVNode("Confirm User Deletion")),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Are you sure you want to delete user '" + _toDisplayString($data.editedItem.name) + "'?", 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              color: "blue darken-1",
                              text: "",
                              onClick: $options.closeDelete
                            }, {
                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                _createTextVNode("Cancel")
                              ])),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              color: "blue darken-1",
                              text: "",
                              onClick: $options.deleteItemConfirm
                            }, {
                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                _createTextVNode("OK")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          "item.actions": _withCtx(({ item }) => [
            _createVNode(_component_v_icon, {
              class: "mr-2",
              small: "",
              onClick: ($event: any) => ($options.editItem(item))
            }, {
              default: _withCtx(() => _cache[27] || (_cache[27] = [
                _createTextVNode("mdi-pencil")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_v_icon, {
              small: "",
              onClick: ($event: any) => ($options.deleteItem(item))
            }, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode("mdi-delete")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }, 8, ["headers", "group-by", "items", "search", "loading"])
      ]),
      _: 1
    })
  ]))
}