import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex ma-0 pa-0 mr-3 justify-end",
  id: "status-icons",
  style: {"width":"80px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_AppSnackBar = _resolveComponent("AppSnackBar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_FileUploadMonitor = _resolveComponent("FileUploadMonitor")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_IdleOverlayView = _resolveComponent("IdleOverlayView")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    id: "luxweb",
    style: {"overflow":"hidden"}
  }, {
    default: _withCtx(() => [
      (!_ctx.loggedIn && !['Login', 'Logout', 'Unauthorized'].includes(_ctx.currentRouteName))
        ? (_openBlock(), _createBlock(_component_v_overlay, {
            key: 0,
            "z-index": "90000"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                indeterminate: "",
                size: "64"
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_AppSnackBar),
      (_ctx.loggedIn)
        ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
            key: 1,
            modelValue: _ctx.sidebarMenu,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarMenu) = $event)),
            floating: "",
            theme: "dark",
            color: "#363636",
            rail: !_ctx.sidebarMenu
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, null, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    class: "cursor-pointer",
                    "aria-label": "Toggle Sidebar",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.sidebarMenu = !_ctx.sidebarMenu), ["stop"]))
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("mdi-chevron-double-left")
                    ])),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_content, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.name), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_subtitle, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.organization.name), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, {
                lines: false,
                density: "compact",
                nav: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, i) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: i,
                      to: item.path,
                      link: "",
                      style: {"display":"flex !important","flex-direction":"row !important"}
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          icon: item.meta.icon,
                          color: "white"
                        }, null, 8, ["icon"])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_content, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { style: {"color":"white"} }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.meta.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "rail"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_app_bar, {
        class: "ml-0 pl-0",
        id: "appbar",
        color: "grey-darken-4",
        theme: "dark",
        variant: "flat",
        style: {"box-shadow":"none"},
        density: "compact"
      }, {
        default: _withCtx(() => [
          (!_ctx.sidebarMenu)
            ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
                key: 0,
                class: "ml-2",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.sidebarMenu = !_ctx.sidebarMenu), ["stop"])),
                disabled: !_ctx.loggedIn
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_toolbar_title, { class: "ml-2" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("LuxWeb Asset Portal")
            ])),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                  key: 0,
                  class: "mr-2",
                  indeterminate: ""
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_FileUploadMonitor)
          ]),
          (_ctx.loggedIn && 'projectSelector' in _ctx.$route.meta && _ctx.$route.meta['projectSelector'])
            ? (_openBlock(), _createBlock(_component_v_select, {
                key: 1,
                class: "mr-3",
                items: _ctx.availableProjects,
                "item-title": _ctx.getProjectName,
                "item-value": "id",
                placeholder: "Select Project",
                variant: "underlined",
                style: {"max-width":"350px"},
                modelValue: _ctx.selectedProject,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedProject) = $event)),
                density: "compact",
                "hide-details": "auto"
              }, null, 8, ["items", "item-title", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_img, {
            class: "ml-0 pl-0",
            src: "./assets/Lux_Modus_logo.webp",
            contain: "",
            height: "55px",
            "max-width": "140px"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            class: "pa-0",
            fluid: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, { userContext: _ctx.user }, null, 8, ["userContext"])
            ]),
            _: 1
          }),
          _createVNode(_component_IdleOverlayView)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}