import { withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow } from "vue"

const _hoisted_1 = { id: "potree_container" }
const _hoisted_2 = {
  key: 0,
  id: "potree_sidebar_container"
}
const _hoisted_3 = {
  key: 0,
  class: "ml-1 mr-2"
}
const _hoisted_4 = { style: {"position":"absolute","right":"0","max-width":"250px","top":"75px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "pa-0 ma-0 fill-height flex-column",
    id: "pointcloudView",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        class: "pa-0 ma-0 flex-auto flex-grow-1",
        fluid: "",
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              id: "potree_render_area",
              ref: "potree_render_area_ref",
              onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => ($options.removeSelectedBox && $options.removeSelectedBox(...args)), ["delete"]))
            }, null, 544),
            _cache[9] || (_cache[9] = _createElementVNode("div", { id: "potree_map" }, null, -1)),
            ($props.showFullMenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      (!$props.showFullMenu)
        ? (_openBlock(), _createBlock(_component_v_container, {
            key: 0,
            class: "mt-1 ml-5 pr-5",
            id: "pointcloudPanel",
            fluid: "",
            style: {"position":"absolute","top":"30","z-index":"1","background-color":"rgba(0,0,0,0)","color":"White","width":"97%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "d-flex" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        bottom: "",
                        "open-delay": "700"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            icon: "",
                            size: "medium",
                            color: "transparent"
                          }, props, { onClick: $options.focusAllPointClouds }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                  _createTextVNode("mdi-arrow-expand-all")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Show all scans")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "pt-3" }, {
                        default: _withCtx(() => [
                          (_ctx.focusedScanId)
                            ? (_openBlock(), _createBlock(_component_v_menu, {
                                key: 0,
                                "offset-y": "",
                                color: ""
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _withDirectives((_openBlock(), _createBlock(_component_v_chip, _mergeProps({
                                    color: "grey-lighten-5",
                                    "text-color": "white"
                                  }, props), {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_avatar, { class: "mr-2" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, { color: "white" }, {
                                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                                              _createTextVNode("mdi-cloud-outline")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("span", null, _toDisplayString($options.selectedVersion), 1),
                                      ($options.selectedVersion === $options.defaultVersion)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(Default)"))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1040)), [
                                    [_directive_ripple]
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.artifactList, (artifact, index) => {
                                        return (_openBlock(), _createBlock(_component_v_list_item, {
                                          key: index,
                                          onClick: ($event: any) => ($options.selectVersion(artifact))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(artifact), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.focusedScanId && _ctx.canProcess)
                            ? (_openBlock(), _createBlock(_component_v_menu, {
                                key: 1,
                                "offset-y": ""
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _createVNode(_component_v_btn, _mergeProps({
                                    class: "ml-2",
                                    icon: "",
                                    size: "medium",
                                    color: "transparent"
                                  }, props), {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                                          _createTextVNode("mdi-cloud-sync-outline")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1040)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['Process', 'Publish'], (item) => {
                                        return _createVNode(_component_v_list_item, {
                                          key: item,
                                          onClick: ($event: any) => ($options.openProcessOption(item))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      }), 64))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.dialog)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.processingComponent), {
                                key: 2,
                                onFinished: $options.closeProcessOption
                              }, null, 40, ["onFinished"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          (_ctx.focusedScanId && $options.defaultVersion != $options.selectedVersion)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                size: "small",
                                color: "transparent",
                                text: "",
                                dark: "",
                                style: {"text-transform":"none"},
                                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.setDefaultVersion($options.selectedVersion)))
                              }, {
                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createTextVNode("Set as Default")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    class: "flex",
                    cols: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "pt-3" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_menu, { "offset-y": "" }, {
                            activator: _withCtx(({ props }) => [
                              _withDirectives((_openBlock(), _createBlock(_component_v_chip, _mergeProps({
                                color: "grey-lighten-5",
                                "text-color": "white"
                              }, props), {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_avatar, { class: "mr-2" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { color: "white" }, {
                                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                                          _createTextVNode("mdi-palette-outline")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("span", null, _toDisplayString($options.displayAttribute), 1)
                                ]),
                                _: 2
                              }, 1040)), [
                                [_directive_ripple]
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.attributes, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: item.name,
                                      onClick: ($event: any) => ($options.selectAttribute(item.value))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createElementVNode("template", null, [
                            _createVNode(_component_v_icon, {
                              class: "ml-2",
                              color: "white"
                            }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode("mdi-tune-vertical")
                              ])),
                              _: 1
                            }),
                            _cache[17] || (_cache[17] = _createElementVNode("div", {
                              class: "ml-1 mt-1",
                              id: "gradient_schemes"
                            }, null, -1))
                          ])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, $options.showGradients]
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    class: "d-flex justify-top",
                    cols: "2",
                    style: {"align-self":"start"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_slider, {
                        class: "pr-10",
                        density: "compact",
                        size: "small",
                        max: "10",
                        min: "1",
                        "track-color": "grey",
                        color: "white",
                        modelValue: _ctx.selectedPointsize,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPointsize) = $event)),
                        "hide-details": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            slot: "prepend",
                            color: "white"
                          }, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode("mdi-circle-expand")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    class: "d-flex",
                    cols: "2",
                    style: {"align-self":"start"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        bottom: "",
                        "open-delay": "700"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            class: "ml-2",
                            icon: "",
                            density: "compact",
                            size: "medium",
                            color: "transparent"
                          }, props, { onClick: $options.volumeTool }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode("mdi-cube-outline")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode("Volume tool")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, {
                        bottom: "",
                        "open-delay": "700"
                      }, {
                        activator: _withCtx(({ props }) => [
                          (!_ctx.pointExplorer)
                            ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
                                key: 0,
                                class: "ml-2",
                                icon: "",
                                density: "compact",
                                size: "medium",
                                color: "transparent"
                              }, props, {
                                onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.openPointData()))
                              }), {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, null, {
                                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                                      _createTextVNode("mdi-crosshairs")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1040))
                            : (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
                                key: 1,
                                class: "ml-2",
                                icon: "",
                                density: "compact",
                                size: "medium",
                                color: "transparent"
                              }, props, {
                                onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.closePointData()))
                              }), {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, null, {
                                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                                      _createTextVNode("mdi-crosshairs-off")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1040))
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                              _createTextVNode("Point Explorer ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, {
                        bottom: "",
                        "open-delay": "700"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            class: "ml-2",
                            icon: "",
                            density: "compact",
                            size: "medium",
                            color: "transparent"
                          }, props, { onClick: $options.measuringTool }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[24] || (_cache[24] = [
                                  _createTextVNode("mdi-ruler")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode("Measuring tool")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, {
                        bottom: "",
                        "open-delay": "700"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            class: "ml-2",
                            icon: "",
                            density: "compact",
                            size: "medium",
                            color: "transparent"
                          }, props, { onClick: $options.measuringToolHeight }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[26] || (_cache[26] = [
                                  _createTextVNode("mdi-ruler-square")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => _cache[27] || (_cache[27] = [
                              _createTextVNode("Measuring tool with height")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, {
                        bottom: "",
                        "open-delay": "700"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            class: "ml-2",
                            icon: "",
                            density: "compact",
                            size: "medium",
                            color: "transparent"
                          }, props, { onClick: $options.removeAllMeasurements }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[28] || (_cache[28] = [
                                  _createTextVNode("mdi-cancel")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => _cache[29] || (_cache[29] = [
                              _createTextVNode("Remove all measurements ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "1" })
                ]),
                _: 1
              }),
              _createVNode(_component_v_container, { fluid: "" }, {
                default: _withCtx(() => [
                  _cache[32] || (_cache[32] = _createElementVNode("div", { style: {"position":"absolute","right":"120px","top":"35px","z-index":"9000"} }, null, -1)),
                  _createVNode(_component_v_btn_toggle, {
                    style: {"z-index":"9000","padding":"0","height":"22px"},
                    modelValue: _ctx.clip,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.clip) = $event)),
                    tile: "",
                    group: "",
                    density: "compact",
                    size: "x-small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "ma-0",
                        value: "Clip",
                        color: "white",
                        style: {"text-transform":"none","color":"rgba(240,240,240,0.8)","border":"1px rgba(240,240,240,0.1) solid"},
                        variant: "outlined",
                        size: "x-small",
                        density: "compact"
                      }, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("Clip")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        class: "ma-0",
                        value: "Unclipped",
                        outlined: "",
                        size: "x-small",
                        density: "compact",
                        text: "",
                        variant: "outlined",
                        style: {"text-transform":"none","color":"rgba(240,240,240,0.8)","border":"1px rgba(240,240,240,0.1) solid"}
                      }, {
                        default: _withCtx(() => _cache[31] || (_cache[31] = [
                          _createTextVNode("Inverse Clip ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_container, { fluid: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        (_ctx.point.coordinates.x)
                          ? (_openBlock(), _createBlock(_component_v_card, {
                              key: 0,
                              color: "transparent"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_subtitle, {
                                  class: "mb-0 pb-0",
                                  style: {"color":"white"}
                                }, {
                                  default: _withCtx(() => [
                                    _cache[33] || (_cache[33] = _createElementVNode("b", null, " Projection: ", -1)),
                                    _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.projection), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_subtitle, {
                                  class: "mb-0 pb-0",
                                  style: {"color":"white"}
                                }, {
                                  default: _withCtx(() => [
                                    _cache[35] || (_cache[35] = _createElementVNode("b", null, " Coordinates: ", -1)),
                                    _cache[36] || (_cache[36] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" X: " + _toDisplayString(_ctx.point.coordinates.x) + " ", 1),
                                    _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" Y: " + _toDisplayString(_ctx.point.coordinates.y) + " ", 1),
                                    _cache[38] || (_cache[38] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" Z: " + _toDisplayString(_ctx.point.coordinates.z), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_subtitle, {
                                  class: "mb-0 pb-0",
                                  style: {"color":"white"}
                                }, {
                                  default: _withCtx(() => [
                                    _cache[39] || (_cache[39] = _createElementVNode("b", null, " Intensity: ", -1)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.point.intensity), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_text_field, {
                                  class: "mt-1",
                                  placeholder: "Click to Add Title",
                                  modelValue: _ctx.newTitle,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newTitle) = $event)),
                                  dark: "",
                                  density: "compact",
                                  filled: "",
                                  flat: "",
                                  "hide-details": "",
                                  maxlength: "60"
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_v_textarea, {
                                  class: "mt-1",
                                  placeholder: "Click to Add Comment",
                                  modelValue: _ctx.newComment,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newComment) = $event)),
                                  dark: "",
                                  density: "compact",
                                  filled: "",
                                  flat: "",
                                  "hide-details": "",
                                  maxlength: "60",
                                  rows: "2",
                                  "row-height": "20",
                                  "no-resize": ""
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_v_btn, {
                                  dark: "",
                                  text: "",
                                  onClick: _cache[8] || (_cache[8] = ($event: any) => ($options.createAnnotation(_ctx.newTitle, _ctx.newComment)))
                                }, {
                                  default: _withCtx(() => _cache[40] || (_cache[40] = [
                                    _createTextVNode("Add")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}