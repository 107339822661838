import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 flex",
  id: "trajectory-processing-run"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_img = _resolveComponent("v-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table, {
      class: "elevation-0",
      headers: $data.headers,
      items: $props.trajectoryProcessingRuns || [],
      "item-key": "id",
      flat: "",
      density: "compact",
      "hide-default-footer": ""
    }, {
      "item.sessionId": _withCtx(({ item }) => [
        _createVNode(_component_v_tooltip, {
          location: "bottom",
          "open-delay": 700
        }, {
          activator: _withCtx(({ attrs }) => [
            _createElementVNode("div", _normalizeProps(_guardReactiveProps(attrs)), [
              _createVNode(_component_router_link, {
                to: { name: 'TrajectoryProcessing', params: { search: item.sessionId } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.sessionId), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ], 16)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "pa-0 ma-0" }, {
              default: _withCtx(() => [
                _createTextVNode("id: " + _toDisplayString(item.id), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      "item.basestationIds": _withCtx(({ item }) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.basestationIds, (basestationId) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: { name: 'Basestation', params: { search: basestationId } },
            key: basestationId
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(basestationId), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      "item.submitted": _withCtx(({ item }) => [
        _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.submitted)), 1)
      ]),
      "item.started": _withCtx(({ item }) => [
        _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.started)), 1)
      ]),
      "item.completed": _withCtx(({ item }) => [
        _createTextVNode(_toDisplayString(_ctx.$filters.localDateTime(item.completed)), 1)
      ]),
      "item.logS3Url": _withCtx(({ item }) => [
        _createVNode(_component_v_icon, {
          disabled: !item.logS3Url,
          onClick: ($event: any) => ($options.showLogFile(item.logS3Url, item.sessionId))
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("mdi-text-box-search-outline")
          ])),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      "item.numSatsS3Url": _withCtx(({ item }) => [
        _createVNode(_component_v_icon, {
          disabled: !item.numSatsS3Url,
          onClick: ($event: any) => ($options.showPlots(item, item.sessionId))
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("mdi-chart-line")
          ])),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      "item.trajectoryAuditS3Url": _withCtx(({ item }) => [
        _createVNode(_component_v_icon, {
          disabled: !item.trajectoryAuditS3Url,
          onClick: ($event: any) => ($options.openInNewWindow(item.trajectoryAuditS3Url))
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("mdi-file-delimited-outline")
          ])),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["headers", "items"]),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: $data.dialogLogFile,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.dialogLogFile) = $event)),
      "max-width": "80vw"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Trajectory processing log results for " + _toDisplayString($data.dialogLogFileTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_textarea, {
                  counter: "",
                  density: "compact",
                  flat: "",
                  rows: "20",
                  "row-height": "22",
                  readonly: "",
                  value: $data.logFile,
                  style: {"font-family":"'Courier New'","font-size":"14px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { "hide-details": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.dialogLogFile = false))
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Close")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: $data.dialogPlots,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.dialogPlots) = $event)),
      "max-width": "80vw"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Trajectory processing audit plots for " + _toDisplayString($data.dialogPlotsTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "d-flex justify-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  "max-height": "400",
                  "max-width": "43vw",
                  contain: "",
                  src: $data.numSatsS3Url
                }, null, 8, ["src"]),
                _createVNode(_component_v_img, {
                  "max-height": "400",
                  "max-width": "43vw",
                  contain: "",
                  src: $data.altitudeSeparationsS3Url
                }, null, 8, ["src"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "d-flex justify-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  "max-height": "400",
                  "max-width": "43vw",
                  contain: "",
                  src: $data.ambStatusS3Url
                }, null, 8, ["src"]),
                _createVNode(_component_v_img, {
                  "max-height": "400",
                  "max-width": "43vw",
                  contain: "",
                  src: $data.distanceSeparationsS3Url
                }, null, 8, ["src"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { "hide-details": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "blue darken-1",
                  text: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.dialogPlots = false))
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Close")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}