import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isShowingDetails,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowingDetails) = $event)),
    "max-width": "400px"
  }, {
    activator: _withCtx(({ props }) => [
      _withDirectives(_createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.showDetails && $options.showDetails(...args))),
        style: {"position":"relative"}
      }, [
        _createVNode(_component_v_progress_circular, {
          modelValue: _ctx.overallSizeProgress,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overallSizeProgress) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_overlay, {
          class: "pb-1",
          absolute: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { small: "" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("mdi-cloud-upload")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, _ctx.hasQueuedOrActiveUploads]
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Upload Progress")
            ])),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (!_ctx.queuedOrActiveUploadCount)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "No files are currently being uploaded."))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.overallProgressDisplay), 1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queuedOrActiveUploads, (workerInfo, index) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: `${index}`,
                  "two-line": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_progress_linear, {
                          modelValue: workerInfo.progressMonitor.progress,
                          "onUpdate:modelValue": ($event: any) => ((workerInfo.progressMonitor.progress) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_list_item_subtitle, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(workerInfo.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}