import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pa-2 ma-2 d-flex",
  id: "activity-logs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ActivityLogList = _resolveComponent("ActivityLogList")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, { width: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { style: {"display":"flex","flex-direction":"row","margin-right":"20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "3" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Activity Logs")
                  ])),
                  _: 1
                }),
                (_ctx.activityLogTypes)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      cols: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          items: _ctx.activityLogTypes,
                          modelValue: $data.activityTypeFilter,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.activityTypeFilter) = $event)),
                          label: "Activity Type",
                          density: "compact",
                          clearable: ""
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                ($data.statusFilterItems)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      cols: "43"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          items: $data.statusFilterItems,
                          modelValue: $data.statusFilter,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.statusFilter) = $event)),
                          label: "Status",
                          density: "compact",
                          clearable: ""
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.orgUsers)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 2,
                      cols: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          items: _ctx.orgUsers,
                          "item-title": 'name',
                          "item-value": 'id',
                          modelValue: $data.userIdFilter,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.userIdFilter) = $event)),
                          label: "User",
                          density: "compact",
                          clearable: ""
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ActivityLogList, {
          activityTypeFilter: $data.activityTypeFilter,
          statusFilter: $data.statusFilter,
          userIdFilter: Number($data.userIdFilter)
        }, null, 8, ["activityTypeFilter", "statusFilter", "userIdFilter"])
      ]),
      _: 1
    })
  ]))
}